import React, { Component } from 'react'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';
 

class ResourceTable extends React.Component {
    onClickCard= (location,isResource) => {
    	var dataFromChild = {
      	lat:location['Latitude'],
      	lng:location['Longitude']
      }
    	if(isResource){
	      dataFromChild = {
	      	lat:location['Latitude'],
	      	lng:location['Longitude']
	      }
   		 }		
    	else {
	      dataFromChild = {
	      	lat:location['Latitude']['$numberDouble'],
	      	lng:location['Longitude']['$numberDouble']
	      }
   	 } 
      this.props.callbackFromParent(dataFromChild)
    }

	render() {
		const { t } = this.props;
		const start = this.props.start;
		const isResource = this.props.isResource;
		const z = require("zebras");
		const locs = this.props.locations.sort((a, b) => a.Category.localeCompare(b.Category))
		const categories = z.unique(z.getCol(['Category'],locs));

		return (
				  <div class = 'resourceTable'>
					   {start
					 	 ? <Card
						      bg='light'
						      text='dark'
						      style={{ 'margin-top':50}}
						    >
						      <Card.Body>
						        <Card.Title> Instructions </Card.Title>
							        <Card.Text>
							        {t('Help')}
							        </Card.Text>
						      </Card.Body>
						    </Card>
					    :  ''
						}
						{locs.length < 1?
							<div>
								<Card
							      bg='light'
							      text='dark'
							      style={{ 'margin-top':10}}
								>
									      <Card.Body>
									      {t('cards.NoResults')}
									      </Card.Body>
									    </Card>
							</div>
							:<div>
				  	{locs.map(location => {
				  		return(
				  			<div>
					  			{isResource 
							    	?	<Card
									      bg='light'
									      text='dark'
									      style={{ 'margin-top':10}}
									      onClick = {() => this.onClickCard(location,isResource)}
									    >
									      <Card.Body>
									        <Card.Title>{t('columns.Category')}: {t('categories.'+location['Category'])} </Card.Title>
									        <Card.Title> {location['index']}. {location[t('columns.Name')]} </Card.Title>
									        <Card.Text>
											  <b>{t('columns.Address')}:</b> {location[t('columns.Address')]}<br/>
											  <b>{t('columns.Nature')}:</b> {location[t('columns.Nature')]}<br/>
											  <b>{t('columns.Target')}:</b> {location[t('columns.Target')]}<br/>
											  <b>{(location[t('columns.Hours')] !== null)
											  ? t('columns.Hours') + ':'
											  : ''} </b>
											  {location[t('columns.Hours')]} <br/>
											  <b>{t('columns.Telephone')}:</b> {location[t('columns.Telephone')]}<br/>
											  <b>{t('columns.Website')}: </b> <a href= {location[t('columns.Website')]}>  {location[t('columns.Website')]}</a>	&nbsp;
									        </Card.Text>
									      </Card.Body>
									    </Card>
									 : <Card
									      bg='light'
									      text='dark'
									      style={{ 'margin-top':10}}
									      onClick = {() => this.onClickCard(location)}
									    >
									      <Card.Body>
									        <Card.Title> {location['index']['$numberInt']}. {location[t('columns.ServiceName')]} </Card.Title>
									        <Card.Text>
											  <b>{t('columns.Address')}:</b> {location[t('columns.Address')]}<br/>
											  <b>{t('columns.Category')}:</b> {location[t('columns.Category')]}<br/>
											  <b>{t('columns.Facility')}:</b> {location[t('columns.Facility')]}<br/>
											  <b>{(location[t('columns.Hours')] !== null)
											  ? t('columns.Hours') + ':'
											  : ''} </b>
											  {location[t('columns.Hours')]} <br/>
											  <b>{t('columns.Telephone')}:</b> {location[t('columns.ServiceTelephone')]}	&nbsp;<br/>
											  <b>{t('columns.ServiceWebsite')}: </b> <a href= 
											  {(location[t('columns.ServiceWebsite')] !== 'N.A.')
											  	? location[t('columns.ServiceWebsite')]
											  	: '#'}
											  	>  {location[t('columns.ServiceWebsite')]}</a>	&nbsp;
									        </Card.Text>
									      </Card.Body>
									    </Card>
								}
							</div>
						)})}
						</div>
				  }
				  </div>
			)
	}
}

ResourceTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ResourceTable);