import React, { Component } from 'react'
import { translate,getLanguage } from 'react-switch-lang';

class Contact extends React.Component {
	render() {
		const { t } = this.props;
		const lang = getLanguage()
		return (
			<div>
			{lang == 'en'
				?<div class = "contact-container">
					    <div class = "row">
							<div class = "col-lg-12">
							<h1>Contact Us</h1>
							<p>Jennifer Wu developed this Resource Map in collaboration with <a href="https://thinkcol.com/">Thinkcol</a>, a Hong Kong-based AI and data science consultancy firm.</p>
						    <p>If you would like to contact us, please contact us at <a href="mailto:theresourcemap@gmail.com">theresourcemap@gmail.com</a>.</p>
					   		</div>
					   	</div>

					    <h1>How Can You Help?</h1>
						<div class = "row">
							<div class = "col-lg-4">
								<img src= './megaphone.png'></img>
								<p>Spread the word and share this resource map with others</p>
							</div>
							<div class = "col-lg-4">
								<img src= './emoji.png'></img>
								<p>Provide us with your comments/suggestions to help us improve the Resource Map via <a href="mailto:theresourcemap@gmail.com">theresourcemap@gmail.com</a>. We welcome any suggestions or comments to further upgrade our website.</p>
							</div>
							<div class = "col-lg-4">
								<img src= './clipboard.png'></img>
								<p>Join us in the community effort to populate a more complete and accurate database. If you would like to add any resources, please kindly click <div class = "contribute-link-div" onClick={()=>this.props.clickNav(false,false,false,false,true,false)}> here</div>. It should only take you 10 minutes.</p>
							</div>						
						</div>

				    	<h1>Data Sources</h1>
				    	<div class = "row">
				    		<div class = "col-lg-12">
							    <h3> Resource Map (<a href="https://www.swd.gov.hk/en/index/site_district/page_inkindass/">Link</a>)</h3>
							    <p> In-kind contribution spreadsheet maintained by the Social Welfare Department (SWD) as of September 2021</p>
							    <p>The original spreadsheet provided by the SWD was only available in Chinese and lacked key information for many resources, such as addresses, opening hours, websites, etc.  Our Resource Map provides more complete and accurate information in both Chinese and English. We also added a list of street sleepers coordinated services and temporary shelter/urban hostels for single persons.</p>
							    <br />
							    <h3> Services Map (<a href="https://data.gov.hk/en-data/dataset/hk-swd-istb-swd-geoinfo-map">Link</a>)</h3>
							    <p>List of Social Services Units maintained by the SWD as of May 2021</p>
							    <p>This map is purely based on the list of general social services units maintained by the SWD.</p>
							 </div>
						</div>
				</div>
				:<div class = "contact-container">
					<div class = "row">
						<div class = "col-lg-12">
							<h1>聯絡我們</h1>
							<p>此網頁是由Jennifer Wu 和 <a href="https://thinkcol.com/">Thinkcol</a> 共同開發。</p>
						    <p>如果你想聯繫我們, 請聯繫<a href="mailto:theresourcemap@gmail.com">theresourcemap@gmail.com</a>.</p>
						</div>
					</div>
					<h1>你如何可以幫助我們？</h1>
					<div class = "row">
						<div class = "col-lg-4">
							<img src= './megaphone.png'></img><br />
							 宣傳並共享此資源圖
						</div>
						<br />
						<div class = "col-lg-4">
							<img src= './emoji.png'></img><br />
							歡迎提供意見。閣下之寶貴意見有助我們進一步提升服務質素。 如果你想更新任何資源或提供任何意見，請聯繫<a href="mailto:theresourcemap@gmail.com">theresourcemap@gmail.com</a>。
						</div>
						<br />
						<div class = "col-lg-4">
							<img src= './clipboard.png'></img><br />
							幫助我們填充更完整和準確的資源。如果你想增加任何新的資源，亦煩請將資料填入這張表格。填寫<div class = "contribute-link-div" onClick={()=>this.props.clickNav(false,false,false,false,true,false)}>這張表格</div>只需十分鐘。
						</div>		
						<br />				
					</div>
					    <h1>數據來源</h1>
					    <div class = "row">
				    		<div class = "col-lg-12">
							    <h3> 資源地圖 (<a href="https://www.swd.gov.hk/en/index/site_district/page_inkindass/">連結</a>)</h3>
							    <p> 由社會福利署提供的實物援助試算表 （至二零二一年九月）</p>
							    <p>此試算表只有中文版本，亦欠缺一些重要資訊，例如地址、開放時間、網站等。本網站的資源地圖有中英文版本，提供更完整及準確的資料。此地圖並加上了一些為露宿者服務及單身人士提供的臨時住宿/旅館。</p>
							    <br />
							    <h3> 服務地圖 (<a href="https://data.gov.hk/en-data/dataset/hk-swd-istb-swd-geoinfo-map">連結</a>)</h3>
							    <p>由社會福利署提供的福利服務單位列表 （至二零二一年五月）</p>
							    <p>此地圖純粹參照上述列表。</p>
							</div>
						</div>
				</div>
			}
			</div>
		)
	}
}

export default translate(Contact);