import React, { Component } from 'react'
import { Map, TileLayer, LayersControl, Marker, Popup, withLeaflet, Tooltip, CircleMarker } from 'react-leaflet'
import { GoogleLayer } from 'react-leaflet-google-v2'
import L from 'leaflet'
import Button from 'react-bootstrap/Button';
import PrintControlDefault from 'react-leaflet-easyprint';
import PrintControl from 'react-leaflet-easyprint';
import printMap from 'react-leaflet-easyprint';
import LocateControl from './LocateControl';
import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';
import Control from 'react-leaflet-control';
 
function iconCreation(iconLink){
	return new L.Icon({
		  iconUrl: iconLink,
		  iconRetinaUrl: iconLink,
		  iconAnchor: [0, 0],
		  popupAnchor: [0, 0],
		  iconSize: [25,25],
		  shadowUrl: iconLink,
		  shadowSize: [25, 25],
		  shadowAnchor: [0, 0],
		})
}

function numberCreation(number){
	return new L.divIcon({
		  className:'dot',
		  html:number,
		  iconAnchor: [0, 0],
		  popupAnchor: [0, 0],
		  iconSize: [25,25],
		  shadowSize: [25, 25],
		  shadowAnchor: [0, 0],
		})
}

var iconDict = [
	{cat:'Elderly / Disabled Services', url:'icons/access.png'},
	{cat:'Haircut', url:'icons/scissors.png'},
	{cat:'Food', url:'icons/food.png'},
	{cat:'Tangible Goods', url:'icons/boxes.png'},
	{cat:'Meals', url:'icons/meal.png'},
	{cat:'Medical Services',url:'icons/medical.png'},
	{cat:'Repair', url:'icons/screwdriver.png'},
	{cat:'Street Sleepers Services', url:'icons/house.png'},
	{cat:'Children & Youth Services', url:'icons/crossing.png'},
	{cat:'Disability Services', url:'icons/access.png'},
	{cat:'District Social Welfare Offices', url:'icons/district.png'},
	{cat:'Elderly Services', url:'icons/old-man.png'},
	{cat:'Family and Integrated Services', url:'icons/family.png'},
	{cat:'Medical Social Services', url:'icons/medical.png'},
	{cat:'Social Security', url:'icons/shield.png'},
]
const locateOptions = {
  position: 'topright',
  enableHighAccuracy: true,
  keepCurrentZoomLevel: true,
  initialZoomLevel:14,
  onActivate: () => {} // callback before engine starts retrieving locations
}
class ResourceMap extends Component {
   state = {
       zoom: 13,
       numbers:false
   }
   onClickTooltip = () => {
    this.setState({tooltip:false})
  }
   showAlert = () => {
    console.log("inside resourcemap.js");
  }

   onClickButton = () => {
   	let numbers = this.state.numbers
   	if(numbers == false)
   	{
    	this.setState({numbers:true})
	}
	else
	{
		this.setState({numbers:false})
	}
  }
   render() {
   	const { BaseLayer} = LayersControl;
	const key = 'AIzaSyB3p-eqYbX0xDakRDS4EbimydgCLfCNRZQ';
	const terrain = 'TERRAIN';
	const road = 'ROADMAP';
	const satellite = 'SATELLITE';
	const hydrid = 'HYBRID';
   	const { t } = this.props;
   	const tooltip = this.props.tootlip;
   	const isResource = this.props.isResource;
   	const start = this.props.start
   	
   	const PrintControl = withLeaflet(PrintControlDefault);
       return (
	              <Map center={[this.props.lat, this.props.lng]} 
				    zoom={this.props.zoom} 
  					className='resourceMap'
  					onFocus={this.props.closeFilter}
  					>
				  <Control position="topright" >

					<label class="label">
					      <div class="button-cover">
					  <div class="toggle">
					    <input class="toggle-state" type="checkbox" name="check" value="check" onChange={ this.onClickButton } />
					    <div class="indicator"></div>
					  </div>
					  <div class="label-text">{t('Icons')}</div>
					  </div>
					</label>
			      </Control>

  					<LocateControl options={locateOptions}/>
  					<LayersControl position='topright'>
			          <BaseLayer  name='OpenStreetMap.Mapnik'>
			            <TileLayer  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"/>
			          </BaseLayer>
			         <BaseLayer checked name='Google Maps Roads'>
			            <GoogleLayer googlekey={key}  maptype={road} />
			          </BaseLayer>     
			        </LayersControl>
				  <TileLayer
				    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				  />
			      {
				  	((!isResource) && (this.props.tooltip))
				  	?<CircleMarker onClick={this.onClickTooltip} center={[22.29442, 114.17274]} color="red" radius={1}>
			          <Tooltip permanent>{t('Tooltip')}</Tooltip>
			         </CircleMarker>
				  	:<div></div>
				  }
				  	{this.props.locations.map(location => {
				  			var point = [location['Latitude'],location['Longitude']]
				  			var numberIcon = numberCreation(location['index'])

				  			if(isResource) 
				  			{
				  				point = [location['Latitude'],location['Longitude']]
				  				numberIcon = numberCreation(location['index'])
				  			}
				  			else { 
				  				point = [location['Latitude']['$numberDouble'], location['Longitude']['$numberDouble']]
				  				numberIcon = numberCreation(location['index']['$numberInt'])
				  			}
				            const pointerIcon = iconCreation(iconDict.filter(cat => cat.cat === location.Category)[0].url)
				            
							return (
								<div>
								<Marker position={point} key={numberIcon} icon=
									{this.state.numbers
										? numberIcon
										: pointerIcon
									} >
								{isResource 
									?  <Popup>
								         	<span><b>{t('columns.Name')}:</b>{location[t('columns.Name')]}</span><br/>
								            <span><b>{t('columns.Address')}:</b> {location[t('columns.Address')]}</span><br/>
								            <span><b>{t('columns.Telephone')}:</b> {location[t('columns.Telephone')]}</span><br/>
								            <span><b>{t('columns.Nature')}</b> {location[t('columns.Nature')]}</span><br/>
								            <span><b>{t('columns.Target')}:</b> {location[t('columns.Target')]}</span><br/>
								         </Popup>
									: 	
								         <Popup>
								            <span><b>{t('columns.ServiceName')}:</b> {location[t('columns.ServiceName')]}</span><br/>
								            <span><b>{t('columns.Address')}:</b> {location[t('columns.Address')]}</span><br/>
								            <span><b>{t('columns.ServiceTelephone')}:</b> {location[t('columns.ServiceTelephone')]}</span><br/>
								            <span><b>{t('columns.Facility')}</b> {location[t('columns.Facility')]}</span><br/>
								         </Popup>
								}
								</Marker>
								</div>							
				 			)})}
					       	</Map>
       )	
   }
}

ResourceMap.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(ResourceMap);

{/*
	<Marker position={point} key={location['index']} icon={pointerIcon} >
         <Popup>
         	<span><b>{t('columns.Name')}:</b>{location[t('columns.Name')]}</span><br/>
            <span><b>{t('columns.Address')}:</b> {location[t('columns.Address')]}</span><br/>
            <span><b>{t('columns.Telephone')}:</b> {location[t('columns.Telephone')]}</span><br/>
            <span><b>{t('columns.Nature')}</b> {location[t('columns.Nature')]}</span><br/>
            <span><b>{t('columns.Target')}:</b> {location[t('columns.Target')]}</span><br/>
         </Popup>
     </Marker>
*/}