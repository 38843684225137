import React from 'react'
import loader from '../box.gif'

const Loader = () => {
    return (
        <div style = {{display: 'flex',  justifyContent:'center', alignItems:'center', flexDirection: 'column',height: '100vh'}}>
            <img src={loader }style={{ width:'200px', alt:'Loading'}}/>
            <h5> Loading </h5>
        </div>
            
       
    )
}

export default Loader