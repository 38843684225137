import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Filter from './components/Filter'
import ServiceFilter from './components/ServiceFilter'
import Contribute from './components/Contribute'
import Contact from './components/Contact'
import Help from './components/Help'
import VerifyLoginPage from './components/Verify'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import PropTypes from 'prop-types';


import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
  getLanguage,
} from 'react-switch-lang';
import en from './en.json';
import cn from './cn.json';



setTranslations({ en, cn });
setDefaultLanguage('cn');
 
// If you want to remember selected language
setLanguageCookie();

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isContact:false,
			isResource:true,
			isMap:true,
			isHelp:false,
			isContribute:false,
			isVerify:false,
			navExpanded:false,
			screenWidth: null,
			lang:'cn'
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	componentDidMount() {
		document.title = 'The Resource Map | Hong Kong | Social Welfare map'
		    	window.addEventListener("load", this.updateWindowDimensions);
    	window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillUnmount() {
	    window.removeEventListener("resize", this.updateWindowDimensions)
	}

	updateWindowDimensions() {
	   this.setState({ screenWidth: window.innerWidth });
	}
    clickNav= (isResource,isMap,isContact,isHelp,isContribute,isVerify) => {
       this.setState({ 
       	isResource:isResource,
       	isMap:isMap,
       	isContact:isContact,
       	isHelp:isHelp,
       	isContribute:isContribute,
       	isVerify:isVerify,
       	navExpanded: false 
       });
   	   console.log(isHelp)
    }

    clickNavLang(lang){
	    window.gtag("event", "page_view", {
	      page_path: '/'+lang
	    });
    	setLanguage(lang);
    	this.setState({
    		navExpanded:false,
    		lang:lang
    	})
    }
	render() {
	const { t } = this.props;
	const isResource = this.state.isResource
	const isContact = this.state.isContact
	const isHelp = this.state.isHelp
	const isContribute = this.state.isContribute
	const isVerify = this.state.isVerify
	const screenWidth = this.state.screenWidth <= 768
	const md = this.state.screenWidth <= 1050 && this.state.screenWidth >= 768
	return (
	 <div>
	  {screenWidth
	  	? (<div>
			<Navbar onToggle={()=>this.setState({navExpanded: !this.state.navExpanded})} expanded={this.state.navExpanded}  expand="md"  fixed="top" className="top-filter">
			  <img src= './logo.png' width = '50px' height = '50px'></img>
			  <Navbar.Brand onClick={()=>this.clickNav(true,true,false,false,false,false)}>{t('Title')}</Navbar.Brand>
			  <Navbar.Toggle />
			  <Navbar.Collapse id="basic-navbar-nav">
			    <Nav className="mr-auto">
			      <Nav.Link onClick={()=>this.clickNav(true,true,false,false,false,false)}>{t('links.Map')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(true,false,false,false,false,false)}>{t('links.Card')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,true,false,false,false,false)}>{t('links.ServiceMap')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,false,false,false,false)}>{t('links.ServiceCard')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,false,true,false,false)}>{t('links.Help')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,true,false,false,false)}>{t('links.Contact')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,false,false,true,false)}>{t('links.Contribute')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNavLang('cn')}>中文</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNavLang('en')}>English</Nav.Link>
			    </Nav>
			  </Navbar.Collapse>
			</Navbar>
			{isHelp
				?<Help />
				:isContact
					?<Contact clickNav={this.clickNav}/>
					:isContribute
						?<Contribute />
						:isVerify
							?<VerifyLoginPage />
							:isResource
					    		?<Filter lang = {this.state.lang} navExpanded = {this.state.navExpanded} map = {this.state.isMap} both = {false} clickNav={this.clickNav}/>
					    		:<ServiceFilter lang = {this.state.lang} navExpanded = {this.state.navExpanded} map = {this.state.isMap} both = {false} clickNav={this.clickNav}/>
			}
		  </div>)
		: (<div>
			<Navbar className="top-filter" onToggle={()=>this.setState({navExpanded: !this.state.navExpanded})} expanded={this.state.navExpanded} expand="md"  fixed="top">
			  <img src= './logo192.png' width = '50px' height = '50px' class = 'logo'></img><br />
			  <Navbar.Brand onClick={()=>this.clickNav(true,true,false,false,false,false)}>{t('Title')}</Navbar.Brand>
			  <Navbar.Toggle />
			  <Navbar.Collapse id="basic-navbar-nav">
			    <Nav className="mr-auto">
			      <Nav.Link onClick={()=>this.clickNav(true,true,false,false,false,false)}>{t('links.Map')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,true,false,false,false,false)}>{t('links.ServiceMap')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,false,true,false,false)}>{t('links.Help')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,true,false,false,false)}>{t('links.Contact')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNav(false,false,false,false,true,false)}>{t('links.Contribute')}</Nav.Link>
			    </Nav>
			    <Nav className="ml-auto">
			      <Nav.Link onClick={()=>this.clickNav(false,false,false,false,false,true)}>{t('links.VerifyLoginPage')}</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNavLang('cn')}>中文</Nav.Link>
			      <Nav.Link onClick={()=>this.clickNavLang('en')}>English</Nav.Link>
			    </Nav>
			  </Navbar.Collapse>
			</Navbar>
			{isHelp
				?<Help />
				:isContact
					?<Contact />
					:isContribute
						?<Contribute />
						:isVerify
							?<VerifyLoginPage />
							:isResource
						    	?md
						    		?<Filter both = {true} md = {true} lang = {this.state.lang} />
						    		:<Filter both = {true} md = {false}  lang = {this.state.lang} />
						     	:<ServiceFilter lang = {this.state.lang} navExpanded = {this.state.navExpanded} map = {this.state.isMap} both = {true} clickNav={this.clickNav}/>
			}</div>)
	  }
	  </div>
	 );
	}
}

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(App);