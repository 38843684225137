import React from 'react'
import { translate, getLanguage } from 'react-switch-lang';

import axios from 'axios';
import './CSS/cssfile.css';
import VerifyPage from '../components/VerifyPage';

class Verify extends React.Component {

	constructor(props) {
		super(props);
	    this.state = {
	    	username:'',
			password:'',
			errormessage:'',
	    	token: false,
		};
	}

	myChangeHandler = (event) => {
		this.setState({[event.target.name] : event.target.value});
	}
	
	/*
	handleSubmit = (event) => {

		axios.post('http://localhost:4000/users/signin', { username: this.state.username, password: this.state.password }).then(response => {
			this.setState({"errormessage" : ""})
			this.setState({"token" : response.data.token})
		}).catch(error => {
			this.setState({"token" : false})
			if (error.response.status === 401) this.setState({"errormessage" : error.response.data.message});
			else this.setState({"errormessage" : "Something went wrong. Please try again later."});
		});

		event.preventDefault();
	}
	
	*/

	handleSubmit = (event) => {
		event.preventDefault();

		var all_stored_pw = {}

		fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/user_pass')
			.then(async response => {
				var all_stored_pw = {}

				const data = await response.json();

				Object.keys(data).forEach(function(key) {
					console.log(data[key])
					console.log(data[key]['password'])
			      	all_stored_pw[data[key]['user']] = data[key]['password']
			    });

			    if (all_stored_pw[this.state.username] === this.state.password){
			    	this.setState({"token" : true})
			    }

			})
		
	}
	

	render() {
		const { t } = this.props;
		const lang = getLanguage()

		if(!this.state.token) {
    		return (
				<div style = {{height: '91vh', 'margin-top':'9vh','padding':'30px','padding-top':'20px'}} class="login-wrapper">
				<h1>Admin Log In</h1>
				<form onSubmit={this.handleSubmit}>
					<div>
					<label>
					<p>Username</p>
					<input type="text" name="username" id="username" value = {this.state.username} onChange={this.myChangeHandler}/>
					</label>
					</div>
					<div>
					<label>
					<p>Password</p>
					<input type="password" name="password" id="password" value = {this.state.password} onChange={this.myChangeHandler}/>
					</label>
					</div>
					<div>
					<button type="submit">Submit</button>
					</div>
				</form>
				</div>
    		)
  		} else {
  			return (
  				<VerifyPage />
  			)
  		}

		
	}
}

export default translate(Verify);