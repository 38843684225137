import React, { Component } from 'react'
import { translate,getLanguage } from 'react-switch-lang';

class Help extends React.Component {
	render() {
		const { t } = this.props;
		const lang = getLanguage()
		return (
			<div>
			{lang == 'en'
				?<div class = "contact-container">
			   <h1>Introduction</h1>
					<p>Government agencies and NGOs have been working hard to serve the low-income populations and street sleepers in Hong Kong, but to date, there is no comprehensive resource map specifically for these groups to use in Hong Kong. The Social Welfare Department maintains a geoinfo map covering general social services, but it does not plot out “in-kind contributions” (e.g. meals, medical vouchers, etc.). The purpose of this app is to close that gap and help maximize access to resources available for the low-income population and to foster coordination among all relevant parties in identifying areas that are lacking support to low-income population. We hope that this website will provide NGOs, social workers, volunteers and direct end-users with an easier way to identify resources to better serve the needy.</p>
					<h1>How do we use the Resource Map？</h1>

					<iframe src="https://www.youtube.com/embed/b5nNL-smjcg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
					
					<div style = {{'font-size':'.8rem'}}>
					<h1>Privacy Policy for The Resource map</h1>

					<p>At The Resource Map, accessible from www.theresourcemap.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by The Resource Map and how we use it.</p>

					<p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

					<p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in The Resource Map. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the <a href="https://www.privacypolicygenerator.info">Free Privacy Policy Generator</a>.</p>

					<h5>Consent</h5>

					<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

					<h5>Information we collect</h5>

					<p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
					<p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>

					<h5>How we use your information</h5>

					<p>We use the information we collect in various ways, including to:</p>

					
					Provide, operate, and maintain our website<br />
					Improve, personalize, and expand our website<br />
					Understand and analyze how you use our website<br />
					Develop new products, services, features, and functionality<br />
					Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes<br />
					Send you emails<br />
					Find and prevent fraud<br />
					<br />
				
					

					<h5>Log Files</h5>

					<p>The Resource Map follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>




					<h5>Advertising Partners Privacy Policies</h5>

					<p>You may consult this list to find the Privacy Policy for each of the advertising partners of The Resource Map.</p>

					<p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on The Resource Map, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

					<p>Note that The Resource Map has no access to or control over these cookies that are used by third-party advertisers.</p>

					<h5>Third Party Privacy Policies</h5>

					<p>The Resource Map's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

					<p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

					<h5>GDPR Data Protection Rights</h5>

					<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
					<p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
					<p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
					<p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
					<p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
					<p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
					<p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
					<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

					<h5>Children's Information</h5>

					<p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

					<p>The Resource Map does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
					</div>
				</div>
				:<div class = "contact-container">
				<h1>引言</h1>
			    <p>香港的政府機構和非政府組織一直在努力為露宿者和低收入人群提供支援服務。但是迄今為止，還沒有一個資訊地圖能夠全面、詳細地羅列出這些資源。香港社會福利署網站提供的“<a href="https://www.swd.gov.hk/tc/index/site_geoinfomap/">資訊地圖</a>”只涵蓋了一般社會服務，並沒有詳盡地列出“<a href="https://www.swd.gov.hk/tc/index/site_district/page_inkindass/">實物援助</a>（非現金援助）”的相關信息。因此，我們開發了這份資源地圖，希望可以填補這一信息空白。</p>
    			<p>此資源地圖為香港的<b>非政府組織、社工、義工和受助者</b>提供了一種更簡便的方法來定位資源（如糧食、醫療、殘疾服務、老年服務等），促進有關方之間的協調，識別有低收入人群居住卻缺乏相關資源的區域，並希望最終能為有服務需要的人群提供更好更有效率的服務。我們希望這份資源地圖可以成為大家共享資源的起點和平台。</p>
				<h1>如何使用地圖？</h1>
				<iframe src="https://www.youtube.com/embed/BMwpeRETlr8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
				</div>
			}
			</div>
		)
	}
}

export default translate(Help);