import React, { Component } from 'react'
import ResourceMap from './ResourceMap'
import ResourceCard from './ResourceCard'
import Loader from './Loader'
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ReactToPrint from 'react-to-print';
import 'react-widgets/dist/css/react-widgets.css';
import Multiselect from 'react-widgets/lib/Multiselect'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  translate,
} from 'react-switch-lang';


class RegionCard extends Component {
	async componentDidMount() {
    var node = document.getElementById("regionNode").querySelector('input')
    var a = ReactDOM.findDOMNode(node);
    a.setAttribute('readonly','');
	}
  render() {
  	const { t } = this.props;
  	let regions = this.props.regions
    let translatedRegions = regions.map((d) => {return (t('regions.'+d))})
	            
  	//props come from the Filter
    return (
      <Multiselect
      	id='regionNode'
      	dropUp
      	value={this.props.regionValue}
      	placeholder={t('regions.Placeholder')}
        data={translatedRegions}
        open={this.props.regionOpen}
      	onToggle = {this.props.setRegionOpen}
        onChange={(value) => {this.props.onCardChange(value)}}
      />
    );
  }
}

RegionCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateRegionCard = translate(RegionCard);


//Category filter component. The main function Filter calls this category card 
//value is to show the values (as in the presentation layer) in the multisect and we need to specify it so we can use the clear function
//on Toggle set open is so that once you click on something the drop box closes (UI/UX thing that only we have for category) Not sure why it has to be a props... can't it be done here?
class CategoryCard extends Component {
		async componentDidMount() {
    var node = document.getElementById("categoryNode").querySelector('input')
    var a = ReactDOM.findDOMNode(node);
    a.setAttribute('readonly','');
		}
  render() {
  	const { t } = this.props;
  	let categories = [t("categories.Disability Services"),t("categories.Children & Youth Services"),t("categories.District Social Welfare Offices"),t("categories.Family and Integrated Services"),t("categories.Medical Social Services"),t("categories.Elderly Services"),t("categories.Social Security")]
  	return (
      <Multiselect
      	dropUp
      	value={this.props.categoryValue}
      	open={this.props.open}
      	onToggle = {this.props.setOpen}
      	placeholder={t('categories.Placeholder')}
      	id='categoryNode'
        data={categories}
        defaultValue={[t("categories.Family and Integrated Services")]}
        onChange={(value) => {this.props.onCardChange(value)}}
      />
    )
  }
}
CategoryCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateCategoryCard = translate(CategoryCard)


class FacilityCard extends Component {
		async componentDidMount() {
	    var node = document.getElementById("facilityNode").querySelector('input')
	    var a = ReactDOM.findDOMNode(node);
	    a.setAttribute('readonly','');
		}
  render() {
  	const { t } = this.props;
  	const facilitiesData = this.props.facilitiesData
  	let filteredLocations = this.props.locations
  	console.log(facilitiesData)
   	return (
      <Multiselect
      	dropUp
      	placeholder={t('facilities.Placeholder')}
      	value={this.props.facilitiesValue}
        data={facilitiesData}
        textField={t('facilities.Title')}
        onChange={(value) => {this.props.onCardChange(value)}}
        id='facilityNode'
      />
    )
  }
}
FacilityCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateFacilityCard = translate(FacilityCard)


class ServiceFilterForm extends Component {

	constructor(props) {
		super(props)
		const { t } = this.props;
		let fac = t('facilities.Title')
		this.state = {
			loading:true,
		  searchValue: "",
		  locations:[],
		  filteredLocations:[],
		  regions: 'all',
		  regionValue:[],
		  categories: [t("categories.Family and Integrated Services")],
		  facilities:['all'],
		  facilitiesValue:[], //to put on the ui for multiselect
		  facilitiesData:[],
		  facilitiesOriginal:[],
		  categoryValue:[t("categories.Family and Integrated Services")], //to put on the ui for multiselect
		  currentRegion: 'all',
		  currentCategory: [t("categories.Family and Integrated Services")],
		  currentFacility: [],
		  lat: 22.29442, //default lat
      lng: 114.17274, //default long
      zoom:10, //default zoom
      tooltip:'true',
		}
	}


	async componentDidUpdate(prevProps) {
		//parentnavExpanded so we can do interaction when click filter at the top bar (apps.js) it will close the filter at the bottom bar
		const parentNavExpanded = this.props.navExpanded
		//pass lang so can clear filter when language change
		const lang = this.props.lang
		if(prevProps.navExpanded != parentNavExpanded) {
			this.setNavExpanded(false)
		}
		if(prevProps.lang != lang){
			this.clearFilter()
		}
	}

	calculateMidpoint= (locations) =>{
			//calculate midpoint based on filtered lcoations
			//console.log(locations)
			if(locations.length == 0){
				// if empty no locatiosn then default
				const avgLat = 22.29442
				const avgLong = 114.17274
				const zoom = 10
				
				return{avgLat,avgLong,zoom}
			}
			else
			{
				//console.log(locations)
				//find the four points between all locations
				var minLat= locations[0]['Latitude']['$numberDouble']
				var maxLat = locations[0]['Latitude']['$numberDouble']
				var minLong = locations[0]['Longitude']['$numberDouble']
				var maxLong = locations[0]['Longitude']['$numberDouble']


				var zoom = 10.5
				locations.forEach(location =>{
					if(location['Latitude']['$numberDouble'] > maxLat )
					{
						maxLat = location['Latitude']['$numberDouble']
					}
					if(location['Latitude']['$numberDouble'] < minLat )
					{
						minLat = location['Latitude']['$numberDouble']
					}
					if(location['Longitude']['$numberDouble'] > maxLong )	
					{
						maxLong = location['Longitude']['$numberDouble']
					}
					if(location['Longitude']['$numberDouble'] < minLong )
					{
						minLong = location['Longitude']['$numberDouble']
					}
					//console.log('minLat ' + minLat)
					//console.log('minLong ' + minLong)
				})
					//console.log('minLat ' + minLat)
					//console.log('maxLat ' + maxLat)
					//console.log('minLong ' + minLat)
					//console.log('maxLong ' + maxLong)
					var avgLat = (parseFloat(minLat) + parseFloat(maxLat))/2
					var avgLong = (parseFloat(minLong) + parseFloat(maxLong))/2
					console.log('avgLat ' + avgLat)
					console.log('avgLong ' + avgLong)
					const diffLat = maxLat - minLat
					const diffLong = maxLong - minLong
					if(diffLong > diffLat)
					{
						//if width is larger than height
						zoom = 10.5
						if(!this.props.both)
						{
						avgLat = avgLat - .06
						}
					}
					else{
						if(!this.props.both) //if mobile then move the point up for the filter
						{
							if (diffLat >= .05){
								zoom = 10.5
								avgLat = avgLat - .06
							}
							if (diffLat < .05 && diffLat >= .03){
								zoom = 11.5
								avgLat = avgLat - .03
							}
							if (diffLat< .03 && diffLat >= .01){
								zoom = 12.2
								avgLat = avgLat -.015
							}
							if (diffLat < .01  && diffLat >= .005){
								zoom = 13.5 
								avgLat = avgLat -.01
							}
							if (diffLat < .005 ){
								zoom = 14 
								avgLat = avgLat -.0075
							}
						}
						else
						{
							if (diffLat >= .05){
								zoom = 10.5
							}
							if (diffLat < .05){
								zoom = 11.5
							}
							if (diffLat < .03 ){
								zoom = 12.2
							}
							if (diffLat < .01 ){
								zoom = 13.5
							}
							if (diffLat < .005 ){
								zoom = 14
							}
						}	
					}
					console.log('diffLat ' + diffLat)

				//console.log(avgLat,avgLong,zoom)
					return{avgLat,avgLong,zoom}
			}
		}

	clearFilter= () => {
				const { t } = this.props;
			//clears the filter
			console.log('clear filter')
			this.setState({
				currentRegion: [],
				region: 'all',
				regionValue:[],
				searchValue:"",
				filteredLocations:this.state.locations,
			  categories: [t("categories.Family and Integrated Services")],
			  facilities:['all'],
				currentCategory: [t("categories.Family and Integrated Services")],
				currentFacility: [],
				categoryValue:[t("categories.Family and Integrated Services")],
				facilitiesValue:[],
				facilitiesData:this.state.facilitiesOriginal,
				lat: 22.29442,
	      lng: 114.17274,
	      zoom:10,
				tooltip:'true'
			}, () => this.filterLocations())

		}

	filterLocations = () => {
		const { t } = this.props;
		let tooltip = this.state.tooltip
		let filteredLocations = this.state.locations
		let cat = this.state.currentCategory
		let facilitiesData = this.state.facilitiesData
		let facilitiesOriginal = this.state.facilitiesOriginal
		let region = this.state.currentRegion
		let facilities = this.state.currentFacility.map(function(item) {
		  return item[t('facilities.Title')]
		})
		let searchValue = this.state.searchValue
		let start = this.state.start
		if (start === true){
			this.setState({start:false})
		}
		if (region !== 'all' && region.length > 0)
		{
			console.log('inside region')
			console.log(region)
			//filter based on region
			filteredLocations = filteredLocations.filter(d => region.includes(d[t('regions.ServiceTitle')]))
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
		else{
			//change back to default if all

			this.setState({
				zoom:10.5,
				lat:22.29442,
				lng: 114.17274
			})
		}
		if (cat !== 'all' && cat.length > 0)
		{
			filteredLocations = filteredLocations.filter(d => cat.includes(d[t('categories.Title')]))
			facilitiesData = facilitiesOriginal.filter(d=> cat.includes(d[t('categories.Title')]))
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
		else
		{
			console.log(facilitiesData)
			facilitiesData = facilitiesOriginal
			console.log(facilitiesData)
		}
		if (facilities.length > 0 && facilities[0] !== undefined && facilities[0] !== 'all')
		{
			filteredLocations = filteredLocations.filter(d => facilities.includes(d[t('facilities.Title')]))
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
		if (searchValue !== ''){
		  	filteredLocations = filteredLocations.filter(d => d[t('columns.ServiceName')].toLowerCase().includes(searchValue.toLowerCase()))
		  	let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
					console.log(filteredLocations)
		this.setState({
			filteredLocations: filteredLocations,
			facilitiesData: facilitiesData,
		}) 
		
	}

	async componentDidMount() {
		window.gtag("event", "page_view", {
      page_path: '/servicemap',
    });
		const res = await axios.get('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/service', {
		    params: {
		     "$limit": 500,
		    }
		})
		const res2 = await axios.get('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/facilities', {
		    params: {
		     "$limit": 500,
		    }
		})
		const facilitiesData = res2.data;
		const locations = res.data;
		const z = require("zebras");
		const regions = z.unique(z.getCol(['District'],locations));
		console.log(z.unique(z.getCol(['分區'],locations)))
		const categories = z.unique(z.getCol(['Category'],locations));
		this.setState({
			loading:false,
			start: false,
			regionOpen:false,
			locations: locations,
			filteredLocations: locations,
			regions: regions,
			categories: categories,
			facilitiesData:facilitiesData,
			navExpanded:true,
			facilitiesOriginal:facilitiesData}, () => {this.filterLocations()});
	};

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  }

  closeNav = () => {
    this.setState({ navExpanded: false });
  }


	handleOrgSearch = (e) => {
		this.setState({searchValue: e.target.value}, () => {this.filterLocations()})
	}

	handleRegionCardChange = (region) => {
		this.setState({currentRegion:region}, () => {this.filterLocations()})
		this.setState({regionValue:region})
		this.setRegionOpen()
	}


	handleCategoryCardChange = (cat) => {
		this.setState({currentCategory:cat,tooltip:false}, () => {this.filterLocations()})
		this.setState({categoryValue:cat})
		this.setOpen()
	}

	handleFacilityCardChange = (fac) => {
		this.setState({currentFacility:fac}, () => {this.filterLocations()})
		console.log(fac)
		this.setState({facilitiesValue:fac})
	}
	myCallback = (dataFromChild) => {
        this.setState({ lat: dataFromChild.lat, lng: dataFromChild.lng });
    }
  //setOpen is for turning the category dropdown off
  setOpen = () => {
  	this.setState(prevState => ({
		  open: !prevState.open
		}));
  }

  setRegionOpen = () => {
  	this.setState(prevState => ({
		  regionOpen: !prevState.regionOpen
		}));
  }

  doNothing = () => {
  }

	render() {

		const map = this.props.map
		const both = this.props.both
		const start = this.state.start
		const lowerBarClass = this.state.navExpanded? "lower-bar" :"lower-bar-close"
		const { t } = this.props;
		return (
			<div>
			{this.state.loading? <Loader/ > :
				<div>
					{both
						? <div class = 'wideDiv' ref={el => (this.componentRef = el)}>
						 	<ResourceMap closeFilter = {this.doNothing} locations={this.state.filteredLocations} lat = {this.state.lat} lng = {this.state.lng}  zoom = {this.state.zoom} isResource = {false} start = {this.state.start} tooltip = {this.state.tooltip} />
						  	<ResourceCard callbackFromParent={this.myCallback} locations ={this.state.filteredLocations} isResource = {false}/>
						  </div>
						:map
					     ?<ResourceMap closeFilter = {this.closeNav} locations={this.state.filteredLocations} lat = {this.state.lat} lng = {this.state.lng} zoom = {this.state.zoom} tooltip = {this.state.tooltip} isResource = {false} start = {this.state.start} ref={el => (this.componentRef = el)}/>
					     :<ResourceCard callbackFromParent={this.myCallback} locations ={this.state.filteredLocations} isResource = {false} start = {this.state.start} ref={el => (this.componentRef = el)}/>
					}
				    <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} defaultExpanded bg="light" expand="lg"  fixed="bottom" className = "custom-navbar">
						  {this.state.navExpanded
						  	?both
						  		?	<div class = "top-bar">
						  			{t('links.Filters')} 

									  <div class = 'search-results' > {'(' + this.state.filteredLocations.length + t('links.searchResults')} </div>
										<div class = 'clearFilters' onClick= {this.clearFilter}>
						  				{t('links.ClearFilters')}
						  			</div>
					  		</div>
						  		:<div style = {{width:'100%',float:'left'}}>
						  			{/*this is to show the word filters in small screen as well as the search results and clear filter*/}
						  			<div style = {{float:'left'}}>
						  				{t('links.Filters')}
						  				{'  (' + this.state.filteredLocations.length + t('links.searchResults')}
						  			</div>
						  			<div class = 'clearFilters' onClick= {this.clearFilter}>
						  				{t('links.ClearFilters')}
						  			</div>
						  		</div>
						  	:	
				  			<div class = 'filter-collapsed'>
						  		<Navbar.Toggle aria-controls="basic-navbar-nav" className= "filter-toggle">
						  		{/*word filter for big screens*/}
						  		<div class = 'filter-words'> {t('links.Filters')}</div>
							    <img src= './filter.png' margin-left = '10px' width = '15px' height = '15px'></img>
							    </Navbar.Toggle>
								    {this.state.navExpanded?
									  		<div></div>
									  		:
										  	<div class = 'hiddenWhenNotCollapse'>
										  		    {/*if small screen and the filter is not expanded*/}
										  	{'(' + this.state.filteredLocations.length + t('links.searchResults')}
										  	</div>
										}
								</div>

							 }
	 					<div class = {lowerBarClass}>
					  <Navbar.Collapse id="basic-navbar-nav">
					  	<TranslateRegionCard  regionValue = {this.state.regionValue} onCardChange = {this.handleRegionCardChange} regions = {this.state.regions} currentRegion = {this.state.currentRegion} regionOpen = {this.state.regionOpen} setRegionOpen = {this.setRegionOpen}/>
						  <TranslateCategoryCard categoryValue = {this.state.categoryValue} onCardChange = {this.handleCategoryCardChange} categories = {this.state.categories} currentCategory = {this.state.currentCategory}/>
					  	<TranslateFacilityCard facilitiesValue = {this.state.facilitiesValue} onCardChange = {this.handleFacilityCardChange} facilitiesData = {this.state.facilitiesData} currentFacility = {this.state.currentFacility}/>
					  	{/*<Form inline>
					      <FormControl type="text" placeholder={t('names.Placeholder')} value={this.state.searchValue} onChange={this.handleOrgSearch}/>
					    </Form>*/}
					    <div class = 'hiddenWhenUnder1050'>
								  {'(' + this.state.filteredLocations.length + t('links.searchResults')}
							</div>
					  </Navbar.Collapse>
					  				  {both
				  	?<div>
				  		    {/*to show print in big screen*/}				  
				  	<Nav.Link><ReactToPrint
				  	  pageStyle='@page { size: auto; margin: 10mm; } @media print { .wideDiv { grid-template-columns: 1fr !important;} .resourceMap{ width:100vw !important; height:40vh !important; }.resourceTable { padding: 50px !important; width:100vw !important; overflow-y:visible !important;} }'
				  	  trigger={() => <a href="#"><img src= './print.png' width = '25px' height = '32px'></img></a>}
			          content={() => this.componentRef}
			       /></Nav.Link>
			       </div>
				  	:<div class = 'toggles'>
						  	{/*shows the card and map toggle*/}
					  		<div class ={map?"cardToggle":"cardToggle on"} onClick={()=>this.props.clickNav(false,false,false,false,false,false)}>
									<img src= './list.png' margin-left = '10px' width = '15px' height = '15px'></img>
						  		{t('links.List')}
						  		</div>
						  		<div class ={map?"mapToggle on":"mapToggle"} onClick={()=>this.props.clickNav(false,true,false,false,false,false)}>
						  		<img src= './map.png' margin-left = '10px' width = '15px' height = '15px'></img>
						  		{t('links.MapToggle')}
						  		</div>
						  		{map?
						  			<div></div>
						  			:								<ReactToPrint
					  	  pageStyle='@page { size: auto; margin: 10mm; } @media print { .resourceMap{  }.resourceTable { padding: 10px !important; width:100% !important; overflow-y:visible !important;} }'
					  	  trigger={() => 
					  	  	<div class ='noDecorationLink'>
					  	  		    {/*shows print icon if map list for mobile*/}
							  	  <a href="#">
							  	  <div class="printToggle" >
							  	  <img src= './print.svg' margin-left = '10px' width = '15px' height = '15px'></img>
							  	  {t('links.Print')}
							  	  </div></a>
					  	  </div>}
				          content={() => this.componentRef}
				       />
						  		}
						  									  		{this.state.navExpanded?
							  			<Navbar.Toggle aria-controls="basic-navbar-nav" style = {{float:'right'}}> {t('links.Done')} </Navbar.Toggle>
							  			:<div></div>
							  		}

				  	</div>
				  }
				  	</div>
					</Navbar>
				 </div>

				}
			</div>

		  )
		}
}



export default translate(ServiceFilterForm);