import React, { Component } from 'react'
import { translate, getLanguage } from 'react-switch-lang';
import { dataCategories, multiCheckTargetRecipientCategory } from '../components/Constant';
import { singleCheckDistrict, category_to_icon_map} from '../components/Constant';

import axios from 'axios';
import Card from 'react-bootstrap/Card'

import './CSS/cssfile.css';


// this is something from the input that will be copied to main database.
// name should match with main database. 
const initialCardDetailState = {
    index:0,
    '機構名稱／服務計劃': '',
    'Organisation name / Service Program': '',
    類別: '',
    Category:'',
    分類別: '',
    'Sub-Category': '',
    服務對象: '',
    'Target Service Recipient': '',
    '不適用': '',
    'Not Applicable': '',
    失業人士: '',
    Unemployed: '',
    低收入人士: '',
    'Low-income': '',
    長者: '',
    Elderly: '',
    殘疾人士: '',
    Disability: '',
    露宿者: '',
    'Street Sleepers': '',
    綜援人士: '',
    'CSSA Recipients': '',
    領取書簿津貼人士: '',
    'School Textbook Allowance Recipients': '',
    突變家庭: '',
    'Families Encountering Sudden Change': '',
    地區: '',
    'District': '',
    地址: '',
    Address: '',
    服務性質及內容: '',
    'Nature of Service': '',
    開放時間: '',
    'Opening Hours': '',
    網站: '',
    Website: '',
    查詢電話: '',
    Telephone: '',
    "Organisation Email": '',
    '需要轉介?': '',
    'Referral Required?': '',
    Longitude: 0,
    Latitude: 0,
    'icon': '',
}

// this is something from the input that is necessary to connect to db (id is used to identify which data in database)
// or to match with the imported list from constant.js.
const initialCardDetailOtherState = {
    unique_id: '',
    contributor_note: '',
    district_fullname: singleCheckDistrict[0],
    category_fullname: dataCategories[0]['categoryName'],
    sub_category_fullname: dataCategories[0]['subCategoryName'][0],
    target_recipient_category: {
          ...multiCheckTargetRecipientCategory
    },
}


class VerifyLoginPage extends React.Component {

  //initialize state
  constructor(props){
    super(props);
    this.state={
      data: '',

      card_details_others: {
        ...initialCardDetailOtherState
      },

      card_details: {
        ...initialCardDetailState
      }
    };
    this.standardBlock = this.standardBlock.bind(this);
    this.renderStateUpload = this.renderStateUpload.bind(this);
  }

  //get data from mongodb for card input.
  async componentDidMount() {
    const temporary_contribution = await axios.get('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/tempcontribution', {
    })
    this.setState({data: temporary_contribution.data})    
  };

  //print all the data to cards on the left
  _createCardsUI(){
    const { t } = this.props;
    let cards = []
    var data = this.state.data;

    for (var i = 0; i < data.length; i++) {
      cards.push(
        <Card
          bg='light'
          text='dark'
          style={{ 'margin-top':10}}
          onClick={this.onClickCard.bind(this, data[i])}
        >
          <Card.Body>
            <Card.Text>
              <b>ID: </b> {data[i].index['$numberInt']}<br/>
              <b>Organisation/Program:</b> {data[i]['Organisation name']} / {data[i]['Program Name']}<br/>
              <b>Category: </b> {data[i].category_fullname}<br/>
              <b>Contributor:</b> {data[i].contributor_name}<br/>
            </Card.Text>
          </Card.Body>
        </Card>
        )
    }
    return cards;
  }

  //when clicked, card information to be used as current state.
  onClickCard(data_card) {

    //category and subcat need processing before imported as state.
    var card_category_cn = data_card.category_fullname.split(/\s(.+)/)[0];
    var card_category_en = data_card.category_fullname.split(/\s(.+)/)[1];
    var card_subcat_cn = data_card.sub_category_fullname.split(/\s(.+)/)[0];
    var card_subcat_en = data_card.sub_category_fullname.split(/\s(.+)/)[1];

    //checkmark for target recipient category need processing before imported as state.
    var targetcategory = data_card.target_recipient_category
    var targetcategory_newstate = {}
    for (var i = 0; i < Object.keys(targetcategory).length; i++) {
      const recipient_category_chinese_english = Object.keys(targetcategory)[i];
      const recipient_category_chinese = recipient_category_chinese_english.split(/\s(.+)/)[0];
      const recipient_category_english = recipient_category_chinese_english.split(/\s(.+)/)[1];

      if (Object.values(targetcategory)[i]) {
        targetcategory_newstate[recipient_category_chinese] = recipient_category_chinese
        targetcategory_newstate[recipient_category_english] = recipient_category_english
      } else {
        targetcategory_newstate[recipient_category_chinese] = null
        targetcategory_newstate[recipient_category_english] = null
      }

    }

    //district need processing before imported as state.
    var card_district_cn = data_card.district_fullname.split(/\s(.+)/)[0];
    var card_district_en = data_card.district_fullname.split(/\s(.+)/)[1];

    //the rest can be imported as state directly (same variable name for card and state.)
    var directinput_carddetailothers = ['unique_id', 'category_fullname', 'sub_category_fullname', 'district_fullname', 
                                        'target_recipient_category', 'contributor_name', 'contributor_email', 'contributor_note']
    var directinput_carddetailothers_newstate = {}
    for (var i = 0; i < directinput_carddetailothers.length; i++) {
        directinput_carddetailothers_newstate[directinput_carddetailothers[i]] = data_card[directinput_carddetailothers[i]]
    }

    var directinput_carddetail= ['index', 'Target Service Recipient', '服務對象', 'Address', '地址', 
                                 'Nature of Service', '服務性質及內容', 'Opening Hours', '開放時間', 
                                 'Website', 'Organisation Email', 'Telephone', 'Referral Required?', '需要轉介?']
    var directinput_carddetail_newstate = {}
    for (var i = 0; i < directinput_carddetail.length; i++) {
        directinput_carddetail_newstate[directinput_carddetail[i]] = data_card[directinput_carddetail[i]]
    }

    //setting state
    this.setState({ 
      card_details_others: { ...this.state.card_details_others, 
        ...directinput_carddetailothers_newstate
      },

      card_details: { ...this.state.card_details, 
        '機構名稱／服務計劃': data_card.機構名稱 + " / " + data_card.計劃名稱,
        'Organisation name / Service Program': data_card['Organisation name'] + " / " +  data_card['Program Name'],
        Category: card_category_en,
        類別: card_category_cn,
        'Sub-Category': card_subcat_en,
        分類別: card_subcat_cn,
        'District': card_district_en,
        地區: card_district_cn,
        網站: data_card.Website, // only one website field, this is a copy.
        查詢電話: data_card.Telephone, // only one telephone field, this is a copy.
        'Longitude': 0, //this should be filled by jennifer
        'Latitude': 0, //this should be filled by jennifer
        icon: category_to_icon_map[data_card.category_fullname],
        ...targetcategory_newstate,
        ...directinput_carddetail_newstate
      },
    })
  }


  // action when input fields are clicked or modified
  // state update when field is updated
  myChangeHandler = (event) => {
    this.setState({ 
      card_details: { ...this.state.card_details, 
        [event.target.name]: event.target.value} 
    });
  }

  myChangeHandlerForNumber = (event) => {
    this.setState({ 
      card_details: { ...this.state.card_details, 
        [event.target.name]: parseFloat(event.target.value)} 
    });
  }

  // state update when category dropdown is updated. 
  // Update its own state, icon, 2 dependant category states, and reset subcat to blank.
  myChangeHandlerCategory = (event) => {
    this.setState({ 
      card_details_others: { ...this.state.card_details_others, 
        [event.target.name]: event.target.value,
        sub_category_fullname: dataCategories[0]['subCategoryName'][0]
      },

      card_details: { ...this.state.card_details, 
        Category: event.target.value.split(/\s(.+)/)[1],
        類別: event.target.value.split(/\s(.+)/)[0],
        icon: category_to_icon_map[event.target.value],
        'Sub-Category': '',
        分類別: '',
      },
    });
  }

  // state update when subcategory dropdown is updated. 
  // Update its own state, update 2 dependant subcat states.
  myChangeHandlerSubCategory = (event) => {
    this.setState({ 
      card_details_others: { ...this.state.card_details_others, 
        [event.target.name]: event.target.value
      },
      card_details: { ...this.state.card_details, 
        'Sub-Category': event.target.value.split(/\s(.+)/)[1],
        分類別: event.target.value.split(/\s(.+)/)[0],
      }, 
    });
  }

  // state update when district dropdown is updated. 
  // Update its own state, update 2 dependant district states.
  myChangeHandlerDistrict = (event) => {
    this.setState({ 
      card_details_others: { ...this.state.card_details_others, 
        [event.target.name]: event.target.value
      },
      card_details: { ...this.state.card_details, 
        'District': event.target.value.split(/\s(.+)/)[1],
        地區: event.target.value.split(/\s(.+)/)[0],
      }, 
    });
  }

  // state update when the target recipient category checkmarks are updated.
  // Update each checkmark own state, update 2 dependant checkmark states.
  myChangeHandlerTargetRec = (event) => {

    const recipient_category_chinese_english = event.target.name;
    const recipient_category_chinese = recipient_category_chinese_english.split(/\s(.+)/)[0];
    const recipient_category_english = recipient_category_chinese_english.split(/\s(.+)/)[1];

    const switched_state = !this.state.card_details_others.target_recipient_category[recipient_category_chinese_english];

    //update own
    this.setState({ 
      card_details_others: { ...this.state.card_details_others,
        target_recipient_category: { ...this.state.card_details_others.target_recipient_category,
          [recipient_category_chinese_english]: switched_state
        }
      }
    })

    //update dependant
    if (switched_state) {
      this.setState({ 
        card_details: { ...this.state.card_details, 
          [recipient_category_chinese]:recipient_category_chinese,
          [recipient_category_english]:recipient_category_english,
        },
      });
    } else {
      this.setState({ 
        card_details: { ...this.state.card_details, 
          [recipient_category_chinese]:null,
          [recipient_category_english]:null,
        },
      });
    }
  }

  // state update when referral radio button are updated.
  // update the referral for chinese language too.
  myChangeHandlerInputReferral = (event) => {
    var selected_value = event.target.value

    if (selected_value === "Yes") {
      var chinese_translation = "是"
    } else {
      var chinese_translation = "否"
    }

    this.setState({
      card_details: { ...this.state.card_details, 
        [event.target.name] : selected_value,
        "需要轉介?" : chinese_translation,
      },
    });
  }

  // approved. delete data in temp and add to contribution data.
  onAcceptClick = (event) => {
    event.preventDefault();
    if (this.state.card_details_others.unique_id !== ""){
      // remove from temp
      fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/delete_temp_contribution', {
        method: 'POST',
        body: JSON.stringify({'unique_id':this.state.card_details_others.unique_id})
      }).then(async response => {
          const data = await response.text();
      });

      // add to db
      fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/post_to_resources_contribution', {
            method: 'POST',
            body: JSON.stringify(this.state.card_details)})
        .then(async response => {
          const data = await response.text();
          alert(data)
          // refresh card data
          fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/tempcontribution')
            .then(async response => {
              const data = await response.json();
              this.setState({data: data})
              this.setState({card_details: { ...this.state.card_details, ...initialCardDetailState}})
              this.setState({card_details_others: { ...this.state.card_details_others, ...initialCardDetailOtherState}})
            })
        })
    } else {
      alert("Please select data.")
    }
  }

  // rejected. delete data in temp.
  onRejectClick = (event) => {
    event.preventDefault();
    if (this.state.card_details_others.unique_id !== ""){
      // remove from temp
      fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/delete_temp_contribution', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({'unique_id':this.state.card_details_others.unique_id})
        }).then(async response => {
            const data = await response.text();
            alert(data)
            
            // refresh card data
            fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/tempcontribution')
              .then(async response => {
                const data = await response.json();
                this.setState({data: data})
                this.setState({card_details: { ...this.state.card_details, ...initialCardDetailState}})
                this.setState({card_details_others: { ...this.state.card_details_others, ...initialCardDetailOtherState}})
              })
          });
    } else {
      alert("Please select data.")
    }
  }

  //function to create standard html chinese-english input block
  standardBlock(title_name, chinese_state, english_state, block_type) {
    if (block_type === "input") {
      return (
        <div>
            <h6>{title_name}</h6>
            <div>
                <label>中文</label>
                <input type="text" name={chinese_state} value = {this.state.card_details[chinese_state]} onChange={this.myChangeHandler} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
            </div>
            <div> 
                <label>English</label>
                <input type="text" name={english_state} value = {this.state.card_details[english_state]} onChange={this.myChangeHandler} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
            </div>
        </div>
        )
    } else if (block_type === "textarea") {
      return (
        <div>
            <h6>{title_name}</h6>
            <div>
                <label>中文</label>
                <textarea type="text" name={chinese_state} value = {this.state.card_details[chinese_state]} onChange={this.myChangeHandler}></textarea>
            </div>
            <div> 
                <label>English</label>
                <textarea type="text" name={english_state} value = {this.state.card_details[english_state]} onChange={this.myChangeHandler}></textarea>
            </div>
        </div>
        )
    }
  }

  //function to display final state to upload 
  renderStateUpload() {
      const resultList = [];
      for(let i = 0; i < Object.keys(this.state.card_details).length; i++) {
          let title = Object.keys(this.state.card_details)[i]
          let final_state = this.state.card_details[title];

          if (title === 'index'){
            resultList.push(
              <div>
                <label>{title}</label>
                <text>{final_state['$numberInt']}</text>
              </div>
            ) 
          } else {
            resultList.push(
              <div>
                <label>{title}</label>
                <text>{final_state}</text>
              </div>
            ) 
          }
      }
      return resultList;
  }


  //render
  render() {

    //all constants, mostly to create dropdown and  checkmark.
    const {t} = this.props;
    const lang = getLanguage()

    const categories = dataCategories.map((category) => (
      <option key={category.categoryName} value={category.categoryName}>
          {category.categoryName}
      </option>
    ));

    const subcat = dataCategories.find(item => item.categoryName === this.state.card_details_others.category_fullname)?.subCategoryName.map((subcatlist) => (
      <option key={subcatlist} value={subcatlist}>
          {subcatlist}
      </option>
    ));

    const multicheck = Object.keys(multiCheckTargetRecipientCategory).map((entry) => (
      <div id="textonleft">
          <input type="checkbox" name={entry} key={Math.random()} defaultChecked={this.state.card_details_others.target_recipient_category[entry]} onChange={this.myChangeHandlerTargetRec}></input> {entry}
      </div>
    ));

    const singlecheck = singleCheckDistrict.map((entry) => (
      <option key={entry} value={entry}>
          {entry}
      </option>
    ));

    var card_length = this._createCardsUI().length


    // output page
    return (
      <div style = {{height: '91vh', 'margin-top':'9vh','padding':'30px','padding-top':'20px'}} class="splitScreen">

        <div class="topPane">
          <h6> There are {card_length} contributions to be reviewed. </h6>
          {this._createCardsUI()}
        </div>

        <div class="middlePane">
          <form id="form_verify">
            <h6> Form Information (Can be modified before submitting the data)</h6>

            <label> ID</label>
            <text> {this.state.card_details_others.unique_id} </text>
            <br></br>

            {this.standardBlock("Organisation / Program", "機構名稱／服務計劃", "Organisation name / Service Program", "input")}

            <div>
              <h6>Category and Sub-Category</h6>
              <div>
                  <label>Category</label>
                  <select name="category_fullname" value={this.state.card_details_others.category_fullname} onChange={this.myChangeHandlerCategory}>
                    {categories}
                  </select>
              </div>
              <div>                
                  <label>Sub-Category</label>
                  <select name="sub_category_fullname" value={this.state.card_details_others.sub_category_fullname} onChange={this.myChangeHandlerSubCategory}>
                    {subcat}
                  </select>
              </div>
            </div>

            {this.standardBlock("Target Service Recipient", "服務對象", "Target Service Recipient", "textarea")}

            <div>
              <h6>Target Service Recipient Category</h6>
              {multicheck}
            </div>

            <div>
              <h6>District</h6>
              <div id="textonleft">
                <select name="district_fullname" value={this.state.card_details_others.district_fullname} onChange={this.myChangeHandlerDistrict}>
                  {singlecheck}
                </select>
              </div>
            </div>

            {this.standardBlock("Address", "地址", "Address", "input")}
            {this.standardBlock("Nature of Service", "服務性質及內容", "Nature of Service", "input")}
            {this.standardBlock("Opening Hours", "開放時間", "Opening Hours", "input")}
            {this.standardBlock("Website", "網站", "Website", "input")}

            <div>
                <h6>Organization Email</h6>
                <div> 
                    <input type="text" name='Organisation Email' value = {this.state.card_details['Organisation Email']} onChange={this.myChangeHandler} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
                </div>
            </div>


            {this.standardBlock("Telephone", "查詢電話", "Telephone", "input")}

            <div>
              <h6>Referral?</h6>
              <div id="textonleft">
                <text><input type="radio" name="Referral Required?" value='Yes' checked={this.state.card_details["Referral Required?"] === "Yes"} onChange={this.myChangeHandlerInputReferral}></input>需要 Yes</text><br></br>
                <text><input type="radio" name="Referral Required?" value='No' checked={this.state.card_details["Referral Required?"] === "No"} onChange={this.myChangeHandlerInputReferral}></input>不需要 No</text>
              </div>
            </div>

            <div>
                <h6>Geographic Coordinate</h6>
                <div>
                    <label>Longitude</label>
                    <input type="number" name="Longitude" value = {this.state.card_details.Longitude} onChange={this.myChangeHandlerForNumber} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
                    <text> HK Longitude 113.835278 ~ 114.441667 </text>
                </div>
                <div> 
                    <label>Latitude</label>
                    <input type="number" name="Latitude" value = {this.state.card_details.Latitude} onChange={this.myChangeHandlerForNumber} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
                    <text> HK Latitude 22.153889 ~ 22.562222 </text>
                </div>
            </div>

            <hr></hr>

            <div>
              <h6>Contributor Details</h6>
              <div>
                <label >Contributor Name</label>
                <text>{this.state.card_details_others.contributor_name}</text>
              </div>

              <div>
                <label >Contributor Email</label>
                <text>{this.state.card_details_others.contributor_email}</text>
              </div>

              <div>
                <label >Contributor Note</label>
                <text>{this.state.card_details_others.contributor_note}</text>
              </div>
            </div>

          </form>
        </div>

        <div class="bottomPane" id="resultState">
          <h6> Please double check this section before confirming. This will be sent to database. </h6>
          {this.renderStateUpload()}

          <div>
              <button type="button" onClick={this.onAcceptClick}>Accept</button>
              <button type="button" onClick={this.onRejectClick}>Reject</button>
          </div>
        </div>

      </div>
    )
  }
}

export default translate(VerifyLoginPage);