import React from 'react'
import { translate,getLanguage } from 'react-switch-lang';
import { dataCategories, multiCheckTargetRecipientCategory, singleCheckDistrict } from '../components/Constant';

import './CSS/cssfile.css';

class Contribute extends React.Component {

	constructor(props) {
		super(props);
	    this.state = {

	    	//data can be inserted directly.
	    	index:0,
	    	'Program Name':'',
        	計劃名稱:'',
        	'Organisation name':'',
       		機構名稱: '',
	    	'Target Service Recipient': '',
	    	服務對象: '',
	    	Address: '',
			地址: '',
			'Nature of Service': '',
			服務性質及內容: '',
			'Opening Hours': '',
			開放時間: '',
			Website: '',
			Telephone: '',
			"Organisation Email": '',
			"Referral Required?": "No",
			"需要轉介?": "否",

	    	//data from contributor need to be further processed
	    	unique_id: '',
	    	category_fullname: dataCategories[0]['categoryName'],
	    	sub_category_fullname: dataCategories[0]['subCategoryName'][0],
	    	district_fullname: singleCheckDistrict[0],
	    	target_recipient_category: {
	    		...multiCheckTargetRecipientCategory
	    	},

	    	//data from contributor that are not needed
	    	contributor_name: '',
	    	contributor_email: '',
	    	contributor_note: '',

	    	//validation
	    	all_input_valid: {
	    		'Program Name':false,
        		計劃名稱:false,
        		'Organisation name':false,
       			機構名稱: false,
       			category_fullname: false,
	    		sub_category_fullname: false,
	    		'Target Service Recipient': false,
	    		服務對象: false,
	    		target_recipient_category: 0,
	    		Address: false,
				地址: false,
				'Nature of Service': false,
				服務性質及內容: false,
				'Opening Hours': false,
				開放時間: false,
				Website: false,
				Telephone: false,
				"Organisation Email": false,
	    	},
		};
	}

	// action when input fields are clicked or modified
	myChangeHandlerInput = (event) => {
		this.setState({[event.target.name] : event.target.value});

		//verification
		if ((event.target.value === "") || (event.target.value === "--選擇類別 Choose Category--")) {
			this.setState({ 
	      		all_input_valid: { ...this.state.all_input_valid, 
	        		[event.target.name]: false} 
	    	});
		} else {
			this.setState({ 
	      		all_input_valid: { ...this.state.all_input_valid, 
	        		[event.target.name]: true} 
	    	});
		}
	}

	myChangeHandlerInputCategory = (event) => {
		this.setState({
			[event.target.name] : event.target.value,
			sub_category_fullname: dataCategories[0]['subCategoryName'][0]
		});

		//verification
		if (event.target.value === "--選擇類別 Choose Category--") {
			this.setState({ 
	      		all_input_valid: { ...this.state.all_input_valid, 
	        		[event.target.name]: false} 
	    	});
		} else {
			this.setState({ 
	      		all_input_valid: { ...this.state.all_input_valid, 
	        		[event.target.name]: true} 
	    	});
		}
	}

	myChangeHandlerTargetRec = (event) => {
		this.setState({ 
      		target_recipient_category: { ...this.state.target_recipient_category, 
        		[event.target.name]: !this.state.target_recipient_category[event.target.name]} 
    	});

    	//verification
    	if (!this.state.target_recipient_category[event.target.name] === true){
			this.setState({ 
	      		all_input_valid: { ...this.state.all_input_valid, 
	        		target_recipient_category: this.state.all_input_valid.target_recipient_category + 1 } 
	    	});
    	} else { 
    		this.setState({ 
	      		all_input_valid: { ...this.state.all_input_valid, 
	        		target_recipient_category: this.state.all_input_valid.target_recipient_category - 1 } 
	    	});
    	}
	}

	myChangeHandlerInputReferral = (event) => {
		var selected_value = event.target.value

		if (selected_value === "Yes") {
			var chinese_translation = "是"
		} else {
			var chinese_translation = "否"
		}

		this.setState({
			[event.target.name] : selected_value,
			"需要轉介?" : chinese_translation,
		});
	}


    // action when submit is pressed - send to mongo.
    handleSubmit = async (event) => {

    	event.preventDefault();

    	// add date when submitted as id.
    	await this.setState({
    		unique_id: Date.now().toString()
    	})

    	//check verification:
		var all_check_field = Object.keys(this.state.all_input_valid)
		var number_false = 0

    	for (var i = 0; i < all_check_field.length; i++) {
    		var field_name = all_check_field[i]
    		var field_value = this.state.all_input_valid[field_name]
        	if ((field_value === false) || (field_value === 0)){
        		number_false = number_false + 1
        	}        	
    	}

    	// if no error on input submit. else, alert.
    	if (number_false === 0){
	    	// count how many data contribution. index starts with 1000.
			fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/permanent_temporary_contribution')
			.then(async response => {
				const data = await response.json();
				const existing_data_len = data.length
				this.setState({index: existing_data_len + 1000})

				// send to temp contribution databases (2 databases)
				fetch('https://us-east-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/theresourcemap-fjzbd/service/api/incoming_webhook/post_to_tempcontribution', {
				method: 'POST',
				body: JSON.stringify(this.state)
				}).then(async response => {
				  const data = await response.text();
				  alert(data)
				});
			})
    	} else {
    		alert("煩請輸入表格中所有的欄目。/ Please fill all the input fields.")
    	}
    }

    //render
	render() {

		//all constants, mostly to create dropdown, checkmark and radio.
		const { t } = this.props;
		const lang = getLanguage()

		const categories = dataCategories.map((category) => (
			<option key={category.categoryName} value={category.categoryName}>
		  		{category.categoryName}
			</option>
		));

		const subcat = dataCategories.find(item => item.categoryName === this.state.category_fullname)?.subCategoryName.map((subcatlist) => (
			<option key={subcatlist} value={subcatlist}>
		  		{subcatlist}
			</option>
		));

		const multicheck = Object.keys(multiCheckTargetRecipientCategory).map((entry) => (
			<div>
				<text>
					<input type="checkbox" name={entry} defaultChecked={this.state.target_recipient_category[entry]} onChange={this.myChangeHandlerTargetRec}></input> {entry}
				</text>
			</div>
		));

		const singlecheck = singleCheckDistrict.map((entry) => (
			<option key={entry} value={entry}>
		  		{entry}
			</option>
		));

		// output page
		return (
			<div>
				<div class = "contact-container">

			    	<h1>數據收集表 Data Collection Form</h1>
			    	<p>此數據收集表的目的是收集尚未包含在我們資源地圖資料集的數據。請相應地填寫此表格。驗證完成後，我們將包含你所提供的數據在資源地圖的資料集。如果能以英文和中文提供你的答覆，我們將不勝感激。</p>
					<p>This purpose of this form is to collect additional resource data that is not currently included in our dataset. Please fill in this form accordingly. Once we complete the validation process successfully, your data will be included our dataset. We would be extremely grateful if you could provide your responses in both *English and Chinese* if possible.</p>
					<div class = "data-collection-form-container">
						<form id="form_contribute" class="form_contribute" onSubmit={this.handleSubmit}>

							<h5>主要資料 Main Information</h5>

							<h6>計劃名稱 / Program Name</h6>
				            <div>
				                <label>中文 Chinese</label>
				                <input type="text" name="計劃名稱" value = {this.state.計劃名稱} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
				            </div> 
				           	<div>
				                <label>英文 English</label>
				                <input type="text" name="Program Name" value = {this.state['Program Name']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
				            </div>

				            <h6>機構名稱 / Organization Name</h6>
				            <div>
				            	<label>中文 Chinese</label>
				                <input type="text" name="機構名稱" value = {this.state.機構名稱} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
				            </div>
				            <div> 
				                <label>英文 English</label>
				                <input type="text" name="Organisation name" value = {this.state['Organisation name']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
				            </div>

				            <h6>計劃屬於以下哪一類別？ / Which of the following categories does the program fall under?</h6>
				            <div>
				            	<label>類別 Category</label>
				            	<select name="category_fullname" value={this.state.category_fullname} onChange={this.myChangeHandlerInputCategory}>
						            {categories}
						        </select>
							</div>
							<div>
				            	<label>分類別 Sub-Category</label>
								<select name="sub_category_fullname" value={this.state.sub_category_fullname} onChange={this.myChangeHandlerInput}>
									{subcat}
							  	</select>
							</div>
							<text id="indentmore" class="smallletter">注意：如果分類別選擇了“其他”，請在下面的"附加備忘"中提供解釋 。</text><br></br>
							<text id="indentmore" class="smallletter">Note: If "Other" is selected for the Sub-Category, please provide explanation on the Additional Note below</text>

							<hr></hr>

							<h5>其他資料 Other Information</h5>

							<h6>請簡單描述服務對象 / Please provide a simple description on your program's target recipients</h6>
				            <div>
				            	<label>中文 Chinese</label>
				            	<textarea type="text" name="服務對象" value={this.state.服務對象} onChange={this.myChangeHandlerInput}></textarea>
							</div>
							<div>
				            	<label>英文 English</label>
				            	<textarea type="text" name='Target Service Recipient' value={this.state['Target Service Recipient']} onChange={this.myChangeHandlerInput}></textarea>
							</div>


							<h6>計劃的服務對象屬於以下哪些類別？/ Which categories do the program's target recipients fall under? </h6>
							{multicheck}


					        <h6>計劃在哪一個地區？/ Which of the following district does the program fall under? </h6>
				            <div class="form-field">
				            	<label>地區 District</label>
				            	<select name="district_fullname" value={this.state.district_fullname} onChange={this.myChangeHandlerInput}>
						            {singlecheck}
						        </select>
							</div>

							<h6>請提供項目地址 / Please provide the service program's address.</h6>
				            <div class="form-field">
				            	<label>中文 Chinese</label>
								<input type="text" name="地址" value = {this.state.地址} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>
							<div>
							  	<label>英文 English</label>
				            	<input type="text" name="Address" value = {this.state.Address} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

							<h6>請簡單描述服務性質及內容 / Please provide the Nature of Service.</h6>
							<text id="indentmore" class="smallletter">例子: 熱食服務- 10元優惠晚餐，逢星期一至星期五下午4:30 - 7:30 時供應</text><br></br>
							<text id="indentmore" class="smallletter">Example: Hot Meal Services - $10 discounted dinner available Monday - Friday (4:30pm - 7:30pm)</text>
				            <div class="form-field">
				            	<label>中文 Chinese</label>
								<textarea type="text" name="服務性質及內容" value={this.state['服務性質及內容']} onChange={this.myChangeHandlerInput}></textarea>
							</div>
							<div>
							  	<label>英文 English</label>
				            	<textarea type="text" name="Nature of Service" value={this.state['Nature of Service']} onChange={this.myChangeHandlerInput}></textarea>
							</div>

							<h6>請提供營業時間 / Please provide the opening hours.</h6>
							<text id="indentmore" class="smallletter">例子: 星期一至五：下午4:30 - 晚上7:30</text><br></br>
							<text id="indentmore" class="smallletter">Example：Monday - Friday: 4:30pm - 7:30pm</text>
				            <div class="form-field">
				            	<label>中文 Chinese</label>
								<input type="text" name="開放時間" value = {this.state.開放時間} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>
							<div>
				            	<label>英文 English</label>
				            	<input type="text" name="Opening Hours" value = {this.state['Opening Hours']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

							<h6>請提供計劃的網址（如有）/ Please provide the website of the program (if any)</h6>
				            <div class="form-field">
								<input type="text" name="Website" value = {this.state['Website']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

							<h6>請提供組織的電子郵件地址 / Please provide the email address of the organization</h6>
				            <div class="form-field">
								<input type="text" name="Organisation Email" value = {this.state['Organisation Email']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

							<h6>請提供計劃的聯絡電話 / Please provide the contact number of the program</h6>
				            <div class="form-field">
								<input type="text" name="Telephone" value = {this.state['Telephone']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

							<h6>計劃需要社工轉介嗎? / Does your program require any social workers' referral?</h6>
				            <div class="form-field">
								<text><input type="radio" name="Referral Required?" value='Yes' checked={this.state["Referral Required?"] === "Yes"} onChange={this.myChangeHandlerInputReferral}></input>需要 Yes</text><br></br>
								<text><input type="radio" name="Referral Required?" value='No' checked={this.state["Referral Required?"] === "No"} onChange={this.myChangeHandlerInputReferral}></input>不需要 No</text>
							</div>

							<hr></hr>

							<h5>數據提供者的聯繫信息 Data Provider's Contact Information</h5>
							<text class="smallletter">如果通過驗證或需要進一步驗證，我們將會以您提供的電子郵件與您聯繫。</text><br></br>
							<text class="smallletter">These are optional fields. In case of successful validation, or the need of further validation, we will contact you by the email provided.</text>
							
							<div id='smallspace'></div>

				            <div class="form-field">
				            	<label>你的名字 / Your Name</label>
								<input type="text" name="contributor_name" value = {this.state['contributor_name']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

				            <div class="form-field">
				            	<label>你的聯絡電郵 / Your Contact Email</label>
								<input type="text" name="contributor_email" value = {this.state['contributor_email']} onChange={this.myChangeHandlerInput} onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}></input>
							</div>

				            <div class="form-field">
				            	<label>附加備忘 / Additional Note</label>
								<textarea type="text" name="contributor_note" value={this.state['contributor_note']} onChange={this.myChangeHandlerInput}></textarea>
							</div>

							<input type="submit"></input>

				        </form>
				       </div>
				</div>
			</div>
		)
	}
}

export default translate(Contribute)