import React, { useState, Component } from 'react'
import ResourceMap from './ResourceMap'
import ResourceCard from './ResourceCard'
import Loader from './Loader'
import axios from 'axios';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ReactToPrint from 'react-to-print';
import 'react-widgets/dist/css/react-widgets.css';
import Multiselect from 'react-widgets/lib/Multiselect'
import PropTypes from 'prop-types';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from 'react-dom';
import {  Popover, OverlayTrigger,} from 'react-bootstrap';
import {
  translate,
} from 'react-switch-lang';

//so basically region card is the component to show the region filter. The main function Fitler calls this region card 
class RegionCard extends Component {

	async componentDidMount() {
    var node = document.getElementById("regionNode").querySelector('input')
    var a = ReactDOM.findDOMNode(node);
    a.setAttribute('readonly','');
	}
  render() {
  	const { t } = this.props;
  	let regions = this.props.regions
    let translatedRegions = regions.map((d) => {return (t('regions.'+d))})
	            
  	//props come from the Filter
    return (
      <Multiselect
      	id='regionNode'
      	dropUp
      	value={this.props.regionValue}
      	placeholder={t('regions.Placeholder')}
        data={translatedRegions}
        open={this.props.regionOpen}
      	onToggle = {this.props.setRegionOpen}
        onChange={(value) => {this.props.onCardChange(value)}}
      />
    );
  }
}

RegionCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateRegionCard = translate(RegionCard);

//target filter component. The main function Filter calls this target card 
//value is to show the values (as in the presentation layer) in the multisect and we need to specify it so we can use the clear function
class TargetCard extends Component {
			async componentDidMount() {
    var node = document.getElementById("node").querySelector('input')
    var a = ReactDOM.findDOMNode(node);
    a.setAttribute('readonly','');
		}

  render() {
  	const { t } = this.props;
  	let targets = [t('targets.Low-income'),t('targets.Unemployed'),t('targets.Families Encountering Sudden Change'),t('targets.Street Sleepers'),t('targets.Elderly'),t('targets.Disability'),t('targets.CSSA Recipients'),t('targets.School Textbook Allowance Recipients')]
	return (
      <Multiselect
      	id='node'
      	dropUp
      	value={this.props.targetValue}
      	placeholder={t('targets.Placeholder')}
        data={targets}
        onChange={(value) => {this.props.onCardChange(value)}}
      />
    );
  }
}
TargetCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateTargetCard = translate(TargetCard)

//Category filter component. The main function Filter calls this category card 
//value is to show the values (as in the presentation layer) in the multisect and we need to specify it so we can use the clear function
//on Toggle set open is so that once you click on something the drop box closes (UI/UX thing that only we have for category) Not sure why it has to be a props... can't it be done here?
class CategoryCard extends Component {
	async componentDidMount() {
    var node = document.getElementById("categoryNode").querySelector('input')
    var a = ReactDOM.findDOMNode(node);
    a.setAttribute('readonly','');
		}
  render() {
  	const { t } = this.props;
  	let categories = [t("categories.Food"),t("categories.Meals"),t("categories.Haircut"),t("categories.Elderly"),t("categories.Medical"),t("categories.Tangible Goods"),t("categories.Repair"),t("categories.Street")]
  	return (
      <Multiselect
      	dropUp
      	value={this.props.categoryValue}
      	open={this.props.open}
      	onToggle = {this.props.setOpen}
      	id='categoryNode'
      	placeholder={t('categories.Placeholder')}
        data={categories}
        onChange={(value) => {this.props.onCardChange(value)}}
      />
    )
  }
}
CategoryCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateCategoryCard = translate(CategoryCard)

//SubCategory filter component. The main function Filter calls this subcategory card 
//value is to show the values (as in the presentation layer) in the multisect and we need to specify it so we can use the clear function

class SubCategoryCard extends Component {
		async componentDidMount() {
    var node = document.getElementById("subCategoryNode").querySelector('input')
    var a = ReactDOM.findDOMNode(node);
    a.setAttribute('readonly','');
		}
  render() {
  	const { t } = this.props;
  	const subCatData = this.props.subCatData
  	let filteredLocations = this.props.locations
   	return (
      <Multiselect
      	dropUp
      	placeholder={t('subCategories.Placeholder')}
      	value={this.props.subCatValue}
        data={subCatData}
        textField={t('subCategories.Title')}
        groupBy={t('categories.Title')}
        onChange={(value) => {this.props.onCardChange(value)}}
        id='subCategoryNode'
      />

    )
  }
}
SubCategoryCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export const TranslateSubCategoryCard = translate(SubCategoryCard)

//main Component Filter forms that calls all the stuff above
class FilterForm extends Component {

	constructor(props) {
		super(props)
		this.closePopover = this.closePopover.bind(this)
		this.state = {
			popoverOpen: false,
		  searchValue: "", //search value for searching names
		  locations:[], //original locations
		  filteredLocations:[], // filtered locations (changes when click filter)
		  regions: [], // all the different region
		  regionValue:[],
		  categoryValue:[], //to put on the ui for multiselect
	  	subCatValue:[], //to put on the ui for multiselect
			targetValue:[], //to put on the ui for multiselect
			subCatData:[], //to show only the sub category when cat is clicked
		  targets:[], 
		  subCatOriginal:[], //to store the original for subcat
		  currentRegion: 'all', //what the filter currently click
		  currentCategory: 'all', //what the filter currently click
		  currentTarget:'all', //what the filter currently click
		  loading:true, //whether data is loading to show loading screen
		  currentSubCategory:['all'], //what the filter currently click
		  lat: 22.29442, //default lat
      lng: 114.17274, //default long
      zoom:10, //default zoom
		}
	}
		clearFilter= () => {
			//clears the filter
			console.log('clear filter')
			this.setState({
				currentRegion: 'all',
				currentCategory: 'all',
				currentTarget:'all',
				regionValue:[],
				categoryValue:[],
				subCatValue:[],
				targetValue:[],
				subCatData:[],
				lat: 22.29442,
	      lng: 114.17274,
	      zoom:10,
				filteredLocations: this.state.locations
			})
		}

		calculateMidpoint= (locations) =>{
			//calculate midpoint based on filtered lcoations
			console.log(locations)
			if(locations.length == 0){
				// if empty no location then default
				const avgLat = 22.29442
				const avgLong = 114.17274
				const zoom = 10
				
				return{avgLat,avgLong,zoom}
			}
			else
			{
				//find the four points between all locations
				var minLat= locations[0]['Latitude']
				var maxLat = locations[0]['Latitude']
				var minLong = locations[0]['Longitude']
				var maxLong = locations[0]['Longitude']
				var zoom = 10.5
				locations.forEach(location =>{
					if(location['Latitude'] > maxLat )
					{
						maxLat = location['Latitude']
					}
					if(location['Latitude'] < minLat )
					{
						minLat = location['Latitude']
					}
					if(location['Longitude'] > maxLong )	
					{
						maxLong = location['Longitude']
					}
					if(location['Longitude'] <minLong )
					{
						minLong = location['Longitude']
					}
					//console.log('minLat ' + minLat)
					//console.log('minLong ' + minLong)
				})
				//find avg points
					var avgLat = (minLat + maxLat)/2
					var avgLong = (minLong + maxLong)/2
					const diffLat = maxLat - minLat
					const diffLong = maxLong - minLong
					if(diffLong > diffLat)
					{
						//if width is larger than height
						zoom = 10.5
						if(!this.props.both)
						{
						avgLat = avgLat - .06
						}
					}
					else{
						if(!this.props.both) //if mobile then move the point up for the filter
						{
							if (diffLat >= .05){
								zoom = 10.5
								avgLat = avgLat - .06
							}
							if (diffLat < .05 && diffLat >= .03){
								zoom = 11.5
								avgLat = avgLat - .03
							}
							if (diffLat< .03 && diffLat >= .01){
								zoom = 12.2
								avgLat = avgLat -.015
							}
							if (diffLat < .01  && diffLat >= .005){
								zoom = 13.5 
								avgLat = avgLat -.01
							}
							if (diffLat < .005 ){
								zoom = 14 
								avgLat = avgLat -.0075
							}
						}
						else
						{
							if (diffLat >= .05){
								zoom = 10.5
							}
							if (diffLat < .05){
								zoom = 11.5
							}
							if (diffLat < .03 ){
								zoom = 12.2
							}
							if (diffLat < .01 ){
								zoom = 13.5
							}
							if (diffLat < .005 ){
								zoom = 14
							}
						}	
					}
					//console.log('diffLat ' + diffLat)
					//console.log('avgLat ' + avgLat)
					//console.log('avgLong ' + avgLong)

					
				//console.log('avgLat ' + avgLat)
				//console.log(avgLat,avgLong,zoom)
					return{avgLat,avgLong,zoom}
			}
		}

	filterLocations = () => {
		// main function for filtering locations
		const { t } = this.props;
		function isTarget(location)
		{
			let isTar = false
			tar.forEach((d)=> {
				if (location[d] !== null)
				{
					isTar = true;
				}
			})
			return isTar
		}
		let filteredLocations = this.state.locations
		let cat = this.state.currentCategory
		let region = this.state.currentRegion
		let subCatData = this.state.subCatData
		let subCatOriginal = this.state.subCatOriginal
		let subCat = this.state.currentSubCategory.map(function(item) {
		  return item[t('subCategories.Title')]
		})
		let tar = this.state.currentTarget
		let searchValue = this.state.searchValue
		if (region != 'all' && region.length > 0)
		{
			console.log('inside region')
			console.log(region)
			//filter based on region
			filteredLocations = filteredLocations.filter(d => region.includes(d[t('regions.Title')]))
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
		else{
			//change back to default if all

			this.setState({
				zoom:10.5,
				lat:22.29442,
				lng: 114.17274
			})
		}
		if (cat !== 'all' && cat.length > 0)
		{
			//filter based on category
			filteredLocations = filteredLocations.filter(d => cat.includes(d[t('categories.Title')]))
			subCatData = subCatOriginal.filter(d=> cat.includes(d[t('categories.Title')]))
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})

		}
		else
		{
			//change the subcat back to original if category is all
			subCatData = subCatOriginal
		}
		if (subCat[0] !== undefined && subCat.length > 0)
		{
			//fitler for subcat
			filteredLocations = filteredLocations.filter(d => subCat.includes(d[t('subCategories.Title')]))
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
		if (tar !== 'all' && tar.length > 0)
		{
			//fitler for target
			filteredLocations = filteredLocations.filter(isTarget)
							console.log('inside tarrr')
			let {avgLat, avgLong, zoom} = this.calculateMidpoint(filteredLocations)
			this.setState({
				zoom:zoom,
				lat:avgLat,
				lng:avgLong
			})
		}
		if (searchValue !== ''){
			//filter for search values
		  	filteredLocations = filteredLocations.filter(d => d[t('names.Title')].toLowerCase().includes(searchValue.toLowerCase()))
		}
		//set state so will render and filter
		this.setState({
			filteredLocations: filteredLocations,
			subCatData: subCatData
		}) 
	}


	async componentDidUpdate(prevProps) {
		//parentnavExpanded so we can do interaction when click filter at the top bar (apps.js) it will close the filter at the bottom bar
		const parentNavExpanded = this.props.navExpanded
		//pass lang so can clear filter when language change
		const lang = this.props.lang
		if(prevProps.navExpanded != parentNavExpanded) {
			this.setNavExpanded(false)
			this.closePopover()
		}
		//so when language is changed
		if(prevProps.lang != lang){
			this.clearFilter()
			this.openPopover()
		}
	}

	async componentDidMount() {
		window.gtag("event", "page_view", {
      page_path: '/resourcemap',
    });
		//get get data from mongodb
		const res = await axios.get('https://us-east-1.aws.data.mongodb-api.com/app/theresourcemap-fjzbd/endpoint/api/resources', {
		    params: {
		     "$limit": 500,
		    }
		})
		const res_contribution = await axios.get('https://us-east-1.aws.data.mongodb-api.com/app/theresourcemap-fjzbd/endpoint/api/resources_contribution', {
		    params: {
		     "$limit": 500,
		    }
		})
		const res2 = await axios.get('https://us-east-1.aws.data.mongodb-api.com/app/theresourcemap-fjzbd/endpoint/api/subcat', {
		    params: {
		     "$limit": 500,
		    }
		})

		const subCatData = res2.data;
		const locations = res.data;
		const locations_contribution = res_contribution.data;
		for (let key in locations_contribution) {
			locations.push(locations_contribution[key]);
		}

		const z = require("zebras");
		const regions = z.unique(z.getCol(['District'],locations));
				console.log(z.unique(z.getCol(['地區'],locations)))
		const categories = z.unique(z.getCol(['Category'],locations));
		this.setState({
			open:false,
			regionOpen:false,
			navExpanded:true,
			loading:false,
			locations: locations,
			filteredLocations: locations,
			regions: regions,
			categories: categories,
			subCatData:subCatData,
			subCatOriginal:subCatData,
			show: false})

		this.setState({
			popoverOpen:true
		});

	};


	//handle search. Some has things like categoryvalue so to set up what is shown in multiselect presentation layer

	handleOrgSearch = (e) => {
		this.setState({searchValue: e.target.value}, () => {this.filterLocations()})
	}

	handleRegionCardChange = (region) => {
		this.setState({currentRegion:region}, () => {this.filterLocations()})
		this.setState({regionValue:region})
		this.setRegionOpen()
	}

	handleCategoryCardChange = (cat) => {
		this.setState({currentCategory:cat}, () => {this.filterLocations()})
		this.setState({categoryValue:cat})
		this.setOpen()
	}

	handleSubCategoryCardChange = (subCat) => {
		this.setState({currentSubCategory:subCat}, () => {this.filterLocations()})
		this.setState({subCatValue:subCat})
	}

	handleTargetCardChange = (tar) => {
		this.setState({targetValue:tar}, () => {this.filterLocations()})
		this.setState({currentTarget: tar})
	}

	myCallback = (dataFromChild) => {
        this.setState({ lat: dataFromChild.lat, lng: dataFromChild.lng });
    }


  //to set the fitler expand or not for mobile
  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
    this.closePopover()
  }

  doNothing = () => {
  }

  //setOpen is for turning the category dropdown off
  setOpen = () => {
  	this.setState(prevState => ({
		  open: !prevState.open
		}));
  }

  setRegionOpen = () => {
  	console.log(this.state.regionOpen)
  	this.setState(prevState => ({
		  regionOpen: !prevState.regionOpen
		}));
  }
  //close the filter
  closeNav = () => {
    this.setState({ navExpanded: false });
    this.closePopover()
  }
  closePopover() {
    this.setState({
      popoverOpen: false
    });
  }

  openPopover(){
  	this.setState({
      popoverOpen: true
    });
  }

	render() {
		const map = this.props.map
		const both = this.props.both
		const md = this.props.md
		const placement = 'top'
		const lowerBarClass = this.state.navExpanded? "lower-bar" :"lower-bar-close"
		const togglesClass = this.state.navExpanded? "toggles-margin-top" :"toggles"
		const { t } = this.props;
		const popoverTop = (
		  <Popover id="popover-positioned-top" title="Popover top" bsClass = "popover-dark" className="popover-dark">
		      <Popover.Content>
				      <div style = {{width:'90%',padding:'.5em'}}>{t('Popover')}</div>
				    	<div style = {{width:'10%',marginLeft:'5px'}} onClick = {this.closePopover} class = "closingX">✕</div>
		    </Popover.Content>
		  </Popover>
		);
		return (
			<div >
			{this.state.loading? <Loader/ > :
				<div>
	    {/*the below is to show either both resourcemap and card for big screens while only one of each for small screen*/}
				{both
					? <div class = 'wideDiv' ref={el => (this.componentRef = el)}>
					 	<ResourceMap closeFilter = {this.doNothing} locations={this.state.filteredLocations} lat = {this.state.lat} lng = {this.state.lng} zoom = {this.state.zoom} isResource = {true}/>
					  	<ResourceCard callbackFromParent={this.myCallback} locations ={this.state.filteredLocations} isResource = {true}/>
					  </div>
					: map
					     ?<ResourceMap closeFilter = {this.closeNav} onFocus={this.state.showAlert} locations={this.state.filteredLocations} lat = {this.state.lat} lng = {this.state.lng} zoom = {this.state.zoom} isResource = {true} ref={el => (this.componentRef = el)}/>
					     :<ResourceCard callbackFromParent={this.myCallback} locations ={this.state.filteredLocations} isResource = {true} ref={el => (this.componentRef = el)}/>

				}
	    	{/*to show the nav bar at the bottom*/}
			    <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} defaultExpanded bg="light" expand="md"  fixed="bottom" className = "custom-navbar">
				  {this.state.navExpanded
				  	?both
				  		?	<div class = "top-bar" >
					  			{t('links.Filters')} 
								  <div class = 'search-results' > {'(' + this.state.filteredLocations.length + t('links.searchResults')} </div>
									<div class = 'clearFilters' onClick= {this.clearFilter}>
				  				{t('links.ClearFilters')}
				  			</div>
					  		</div>
				  		:<div style = {{width:'100%',float:'left'}}>
				  			{/*this is to show the word filters in small screen as well as the search results and clear filter*/}

				  			<div style = {{float:'left'}}>
				  				{t('links.Filters')} 
				  				{'  (' + this.state.filteredLocations.length + t('links.searchResults')}
				  			</div>
				  			<div class = 'clearFilters' onClick= {this.clearFilter}>
				  				{t('links.ClearFilters')}
				  			</div>
				  		</div>
				  	:	
				  		<div class = 'filter-collapsed'>
				  		<Navbar.Toggle aria-controls="basic-navbar-nav" className= "filter-toggle">
				  		{/*word filter for big screens*/}
				  		<div class = 'filter-words'> {t('links.Filters')}</div>
					    <img src= './filter.png' margin-left = '10px' width = '15px' height = '15px'></img>
					    </Navbar.Toggle>
						    {this.state.navExpanded?
							  		<div></div>
							  		:
								  	<div class = 'hiddenWhenNotCollapse'>
								  		    {/*if small screen and the filter is not expanded*/}
								  	{'(' + this.state.filteredLocations.length + t('links.searchResults')}
								  	</div>
								}
						</div>

					 }
					
					 <div class = {lowerBarClass}>
						 {md?
						  <Navbar.Collapse id="basic-navbar-nav">
						  	{/*below does the large filters (region,category,subcat,etc) for MD size (so lower than big screen but not mobile*/}
						  	<TranslateRegionCard  regionValue = {this.state.regionValue} onCardChange = {this.handleRegionCardChange} regions = {this.state.regions} currentRegion = {this.state.currentRegion} regionOpen = {this.state.regionOpen} setRegionOpen = {this.setRegionOpen}/>
						  	<TranslateCategoryCard categoryValue = {this.state.categoryValue} onCardChange = {this.handleCategoryCardChange} categories = {this.state.categories} currentCategory = {this.state.currentCategory} open = {this.state.open} setOpen = {this.setOpen}/>
								<TranslateSubCategoryCard subCatValue = {this.state.subCatValue} onCardChange = {this.handleSubCategoryCardChange} subCatData = {this.state.subCatData}/>
						  	<TranslateTargetCard targetValue = {this.state.targetValue} onCardChange = {this.handleTargetCardChange} currentTarget = {this.state.currentTarget}/>
						  	{/*<Form inline>
						      <FormControl type="text" placeholder={t('names.Placeholder')} value={this.state.searchValue} onChange={this.handleOrgSearch}/>
						    </Form>*/}
					 	 </Navbar.Collapse>
						  :			
							
					  <Navbar.Collapse id="basic-navbar-nav">
					  	{/*below does the large filter for normal size as well as if mobile */}

						  <TranslateRegionCard regionValue = {this.state.regionValue} onCardChange = {this.handleRegionCardChange} regions = {this.state.regions} currentRegion = {this.state.currentRegion} regionOpen = {this.state.regionOpen} setRegionOpen = {this.setRegionOpen}/>
						  <TranslateCategoryCard categoryValue = {this.state.categoryValue} onCardChange = {this.handleCategoryCardChange} categories = {this.state.categories} currentCategory = {this.state.currentCategory} open = {this.state.open} setOpen = {this.setOpen}/>
							<div class = 'advanceFilters'>{t('links.AdvancedFilters')}:</div>
								<TranslateSubCategoryCard subCatValue = {this.state.subCatValue} onCardChange = {this.handleSubCategoryCardChange} subCatData = {this.state.subCatData}/>
						  	<TranslateTargetCard targetValue = {this.state.targetValue}  onCardChange = {this.handleTargetCardChange} currentTarget = {this.state.currentTarget}/>
						  	{/*<Form inline>
						      <FormControl type="text" placeholder={t('names.Placeholder')} value={this.state.searchValue} onChange={this.handleOrgSearch}/>
						    </Form>*/}
						   	{/*this is to show the search results on the side when filter is closed*/}
					  </Navbar.Collapse>




						}
					  {both
					  	?<div>
					  		    {/*to show print in big screen*/}				  
					  	<Nav.Link>

		          			<ReactToPrint
							  	  pageStyle='@page { size: auto; margin: 10mm; } @media print { .wideDiv { grid-template-columns: 1fr !important;} .resourceMap{ width:100vw !important; height:40vh !important; }.resourceTable { padding: 50px !important; width:100vw !important; overflow-y:visible !important;} }'
							  	  trigger={() => <a href="#"><img src= './print.png' width = '25px' height = '25px'></img></a>}
						          content={() => this.componentRef}
						       />

							</Nav.Link>
				       </div>
					  	:<div class = {togglesClass}>
							  	{/*shows the card and map toggle*/}
	
							    <OverlayTrigger defaultShow = {true} show = {this.state.popoverOpen} onClick = {this.closePopover} placement="top" overlay={popoverTop}>
							      <div class ={map?"cardToggle":"cardToggle on"} onClick={()=>this.props.clickNav(true,false,false,false,false,false)}>
											<img id="mapPopover" src= './list.png' margin-left = '10px' width = '17px' height = '17px'></img>
								  		{t('links.List')}
								  	</div>
							    </OverlayTrigger>
							  	<div class ={map?"mapToggle on":"mapToggle"} onClick={()=>this.props.clickNav(true,true,false,false,false,false)}>
							  		<img src= './map.png' margin-left = '10px' width = '17px' height = '17px'></img>
								  	{t('links.MapToggle')}
							  	</div>
							  		{map?
							  			<div></div>
							  			:								<ReactToPrint
						  	  pageStyle='@page { size: auto; margin: 10mm; } @media print { .resourceMap{  }.resourceTable { padding: 10px !important; width:100% !important; overflow-y:visible !important;} }'
						  	  trigger={() => 
						  	  	<div class ='noDecorationLink'>
						  	  		    {/*shows print icon if map list for mobile*/}
								  	  <a href="#">
								  	  <div class="printToggle" >
								  	  <img src= './print.svg' class = "print-icon"></img>
								  	  {t('links.Print')}
								  	  </div></a>
						  	  </div>}
					          content={() => this.componentRef}
					       />
							  		}
							  		{this.state.navExpanded?
							  			<Navbar.Toggle aria-controls="basic-navbar-nav" style = {{float:'right'}}> {t('links.Done')} </Navbar.Toggle>
							  			:<div></div>
							  		}
					  	</div>}
				  	</div>
				</Navbar>
				</div>
				}
		  </div>
		  )
		}
}

export default translate(FilterForm);