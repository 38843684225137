// for dropdown in contribute.js and verifypage.js
export const dataCategories = [
  { categoryName: '--選擇類別 Choose Category--', 
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--']
  },
  { categoryName: "長者及傷殘人士服務 Elderly / Disabled Services", 
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "陪老服務 Accompany Elderly", "通訊系統 Communication System", "殯儀安排 Funeral affairs", "聽力受損設備 Impaired hearing equipment", "接送服務 Medical transportation", "營養評估 Nutritious Assessment", "復康用具 Rehab Equipment", "禦寒物品 Winter Preparation Items",  "其他 Other"],
  },
  { categoryName: "食物 Food",
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "麵包 Bread", "食物 Food", "食物轉贈 Food exchange", "食物、衣服及傢具 Food, Clothes and Furniture", "蔬果及麵包 Fresh produce and bread", "水果 Fruits", "餸菜 Meat & Vegetables", "乾糧 Non-perishable food", "米 Rice", "蔬菜、乾糧及麵包 Vegetables, non-perishable food and bread", "其他 Other"],
  },
  { categoryName: "理髮 Haircut",
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "理髮 Haircut", "上門理髮 Home Haircut", "其他 Other"],
  },
  { categoryName: "物資 Tangible Goods", 
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "電器 Electronic applicances","二手電器及傢俱 Electronics and furniture","貨品交換 Goods exchange","生果、麵包及玩具 Fresh Fruits, Bread and Toys","現金/物資援助 Cash/Tangible Goods Subsidy","玩具 Toys","家居用品 Household appliances","綜合服務 Integrated services","低價發售 Discounted sale","乾糧及日常用品 Non-perishable food and daily necessities", "其他 Other"],
  },
  { categoryName: "膳食 Meals", 
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "午餐及晚餐 Lunch & Dinner","膳食 Meals","晚餐 Dinner","綜合服務 Integrated services","三餐 Breakfast, Lunch & Dinner","午餐 Lunch","早餐 Breakfast","飯券 Meal vouchers", "其他 Other"],
  },
  { categoryName: "醫療服務 Medical Services",
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "牙醫 Dental","醫療券 Medical Voucher","白內障 Cataract","藥物 Medicine","西醫 Western Medicine","中醫及視光檢查 Chinese Medicine & Optometry","過渡活躍症資助 ADHD support","中醫 Chinese Medicine", "其他 Other"],
  },
  { categoryName: "維修 Repair",
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "新居入伙及維修 Move-in and repair", "維修及油漆 Home repair and painting", "更換手錶電池 Repair watches' battery", "修改衣服 Sewing", "其他 Other"],
  },
  { categoryName: "露宿者服務 Street Sleepers Services",
  	subCategoryName: ['--選擇分類別 Choose Sub-Category--', "露宿者日間援助服務 Day Relief Service", "露宿者綜合服務 Street Sleepers Co-ordinated Services", "住宿服務單位 Street Sleepers Hostels"],
  },
];

// for finding icon on verifypage.js
export const category_to_icon_map = {
  "長者及傷殘人士服務 Elderly / Disabled Services": 'https://www.shareicon.net/data/2016/08/18/815825_access_512x512.png;width=25;height=25',
  "食物 Food": 'https://www.shareicon.net/data/2015/11/01/148388_food_128x128.png;width=25;height=25',
  "理髮 Haircut": 'https://www.shareicon.net/data/2016/08/18/815356_scissors_512x512.png;width=25;height=25',
  "物資 Tangible Goods": 'https://www.shareicon.net/data/2016/09/23/833798_boxes_512x512.png;width=25;height=25',
  "膳食 Meals": 'https://www.shareicon.net/data/2016/08/18/808813_food_512x512.png;width=25;height=25',
  "醫療服務 Medical Services": 'https://www.shareicon.net/data/2017/03/27/881588_medical_512x512.png;width=25;height=25',
  "維修 Repair": 'https://www.shareicon.net/data/2016/10/18/845239_screwdriver_512x512.png;width=25;height=25',
  "露宿者服務 Street Sleepers Services": 'https://www.shareicon.net/data/2016/07/23/800460_house_512x512.png;width=25;height=25',
}

// for checkbox in contribute.js and verifypage.js
export const multiCheckTargetRecipientCategory = {
	'不適用 Not Applicable': false,
	'失業人士 Unemployed': false,
	'低收入人士 Low-income': false,
	'長者 Elderly': false,
	'殘疾人士 Disability': false,
	'露宿者 Street Sleepers': false,
	'綜援人士 CSSA Recipients': false,
	'領取書簿津貼人士 School Textbook Allowance Recipients': false,
	'突變家庭 Families Encountering Sudden Change': false
};

// for dropdown in contribute.js and verifypage.js
export const singleCheckDistrict = [
	'中西南及離島區 CENTRAL WESTERN, SOUTHERN AND ISLANDS',
	'東區及灣仔區 EASTERN AND WAN CHAI',
	'九龍城及油尖旺區 KOWLOON CITY & YAU TSIM MONG',
	'觀塘區 KWUN TONG',
	'沙田區 SHA TIN',
	'深水埗區 SHAM SHUI PO',
	'大埔及北區 TAI PO AND NORTH',
	'荃灣及葵青區 TSUEN WAN AND KWAI TSING',
	'屯門區 TUEN MUN',
	'黃大仙及西貢區 WONG TAI SIN AND SAI KUNG',
	'元朗區 YUEN LONG'];